
/*============================================================================================*/
/* 1.2 Owl Carousel */
/*============================================================================================*/
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
 .owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1;
  }
  
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */
  }
  
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
  
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
  }
  
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
  
  .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none;
  }
  
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
  }
  
  .owl-carousel.owl-loaded {
    display: block;
  }
  
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block;
  }
  
  .owl-carousel.owl-hidden {
    opacity: 0;
  }
  
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden;
  }
  
  .owl-carousel.owl-drag .owl-item {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab;
  }
  
  /*
   * 	Owl Carousel - Lazy Load Plugin
   */
  .owl-carousel .owl-item {
    /**
              This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
              calculation of the height of the owl-item that breaks page layouts
           */
  }
  
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: all 400ms ease;
  }
  
  .owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0;
  }
  
  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d;
  }
  
  /**
   * Owl Carousel v2.3.4
   * Copyright 2013-2018 David Deutsch
   * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
   */
  /*
   * 	Default theme - Owl Carousel CSS File
   */
  .owl-theme .owl-nav {
    -webkit-tap-highlight-color: transparent;
  }
  
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    font-size: 32px;
    font-size: 2rem;
    outline: none;
  }
  
  .owl-theme .owl-nav [class*='owl-'] {
    color: #222 !important;
    margin: 5px 0;
    padding: 4px 7px;
    display: inline-block;
    cursor: pointer;
  }
  
  .owl-theme .owl-nav [class*='owl-']:hover {
    color: #FFF;
    text-decoration: none;
  }
  
  .owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 10px;
  }
  
  .owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
  }
  
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline;
  }
  
  .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
    outline: none;
  }
  
  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background: transparent !important;
    border: 2px solid #fff;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .owl-theme .owl-dots .owl-dot span {
    position: relative;
    margin: 0 5px;
    border-radius: 50%;
    width: 16px !important;
    height: 16px !important;
    background: transparent !important;
    display: block;
    -webkit-backface-visibility: visible;
    transition: all .3s ease;
    border: 2px solid rgba(255, 255, 255, 0.4);
  }
  .owl-theme .owl-dots .owl-dot span::before {
    content: '';
    background-color: #fff !important;
    display: block;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -4px;
    margin-left: -4px;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .owl-theme .owl-dots .owl-dot.active span::before {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
  
  .owl-theme .owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-30px) !important;
    left: 0;
    width: 100%;
    margin: 0 !important;
  }
  
  .owl-theme.carousel_item_centered_rooms .owl-nav {
    transform: translateY(-50px) !important;
  }
  
  .owl-prev,
  .owl-next {
    width: 45px;
    height: 45px;
    position: absolute;
    top: 0;
    border-radius: 50%;
    display: block !important;
    background: rgba(255, 255, 255, 0.5) !important;
    border: 0;
    line-height: 47px !important;
  }
  
  .owl-prev {
    left: 15px;
  }
  
  .owl-next {
    right: 15px;
  }
  
  .owl-prev i,
  .owl-next i {
    font-size: 36px;
    font-size: 2.25rem;
    color: #555;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .owl-prev i:hover,
  .owl-next i:hover {
    color: #978667;
  }
  
  .owl-prev.disabled i:hover,
  .owl-next.disabled i:hover {
    color: #ccc;
  }