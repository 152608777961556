/*
Theme Name: Paradise
Theme URI: http://www.ansonika.com/paradise/
Author: Ansonika
Author URI: http://themeforest.net/user/Ansonika/

[Table of contents] 

1. SITE STRUCTURE and TYPOGRAPHY
- 1.0 Preloader
- 1.1 Typography
- 1.2 Animations title
- 1.3 Buttons
- 1.4 Structure

2. CONTENT
- 2.1 Home
- 2.2 About
- 2.3 Room list
- 2.4 Room details
- 2.5 News page
- 2.6 Contact page
- 2.7 Error page

3. COMMON
- 3.1 Misc
- 3.2 Datepicker
- 3.3 Forms
- 3.4 Success submit 
- 3.5 Progess Scroll to top
- 3.6 Spacing
- 3.7 Pagination

/*============================================================================================*/
/* 1.  SITE STRUCTURE and TYPOGRAPHY */
/*============================================================================================*/
/*-------- 1.0 Preloader --------*/
@-webkit-keyframes background {
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
}
@-moz-keyframes background {
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
}
@-o-keyframes background {
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
}
@keyframes background {
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
}
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-color: var(--dark-bg-color);
  z-index: 99999999999 !important;
  overflow: hidden;
}
#preloader.loaded {
  -webkit-animation: background 0.65s normal forwards
    cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-animation: background 0.65s normal forwards
    cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-animation: background 0.65s normal forwards
    cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation: background 0.65s normal forwards
    cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition-delay: 1s;
  -moz-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

[data-loader="circle-side"] {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  -webkit-animation: circle infinite 0.95s linear;
  -moz-animation: circle infinite 0.95s linear;
  -o-animation: circle infinite 0.95s linear;
  animation: circle infinite 0.95s linear;
  border: 2px solid #111;
  border-top-color: rgba(255, 255, 255, 0.2);
  border-right-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.2);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
}

@-webkit-keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*-------- 1.1 Typography --------*/
/* rem reference
  10px = 0.625rem
  12px = 0.75rem
  14px = 0.875rem
  16px = 1rem (base)
  18px = 1.125rem
  20px = 1.25rem
  24px = 1.5rem
  30px = 1.875rem
  32px = 2rem
  */
html * {
  -webkit-font-smoothing: antialiased;
}

body {
  background: #fff;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  font-family: var(--webfont);
  color: var(--black-text-color);
  &.mobile-menu-open {
    overflow-y: hidden;
  }
}

main {
  position: relative;
  z-index: 2;
  background-color: var(--light-bg-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--black-text-color);
  font-weight: 700;
  letter-spacing: -0.03em;
  line-height: 1.3;
}

h3,
h4,
h5,
h6 {
  font-weight: 600;
}

.h4,
h4 {
  font-size: calc(1rem + 0.3vw);
  @media (min-width: 1200px) {
    font-size: 1.25rem;
  }
}

p {
  margin-bottom: 25px;
}

strong {
  font-weight: 600;
}

p.lead strong {
  font-weight: 500;
}

hr {
  margin: 30px 0 0 0;
  border-color: #d9e1e6;
  opacity: 1;
}
hr.more_margin {
  margin: 60px 0 60px 0;
}

/* General links color */
a {
  color: var(--secondary-text-color);
  text-decoration: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
}
a:hover,
a:focus {
  color: var(--dark-bg-color);
  text-decoration: none;
  outline: none;
}

.title small {
  text-transform: uppercase;
  color: var(--secondary-text-color);
  letter-spacing: 3px;
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  font-size: 0.75rem;
}
.title h2,
.title h3 {
  font-weight: 700;
  margin-bottom: 15px;
}
.title h2 {
  font-size: 31px;
  font-size: 1.9375rem;
  @media (max-width: 499px) {
    font-size: 26px;
    font-size: 1.6375rem;
  }
}
.title h3 {
  font-size: 31px;
  font-size: 1.9375rem;
  @media (max-width: 499px) {
    font-size: 26px;
    font-size: 1.6375rem;
  }
}
.title.center {
  text-align: center;
}
.title.white small {
  color: var(--primary-text-color);
  opacity: 0.8;
}
.title.white h2,
.title.white h3 {
  color: #fff;
}

.focus-block .title h2,
.form-container .title h2 {
  font-size: 31px;
  font-size: 1.9375rem;
  @media (max-width: 499px) {
    font-size: 26px;
    font-size: 1.6375rem;
  }
}

.focus-block .title {
  @media (max-width: 991px) {
    margin-top: 30px;
    text-align: center;
  }
  @media (max-width: 599px) {
    a.btn_1 {
      margin-top: 15px;
    }
  }
}

.focus-block h3 {
  font-size: 15px;
  font-size: 0.9375rem;
  margin-bottom: 0;
}

/*-------- 1.2 Animations title --------*/
.slide-animated {
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -ms-transform: translateY(20px);
  -o-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
  visibility: hidden;
  -moz-transition: all 0.05s ease;
  -o-transition: all 0.05s ease;
  -webkit-transition: all 0.05s ease;
  -ms-transition: all 0.05s ease;
  transition: all 0.05s ease;
}

.is-transitioned .slide-animated {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  opacity: 1;
  visibility: visible;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.is-transitioned .slide-animated.one {
  transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
}
.is-transitioned .slide-animated.two {
  transition-delay: 0.7s;
  -webkit-transition-delay: 0.7s;
  -moz-transition-delay: 0.7s;
  -o-transition-delay: 0.7s;
}
.is-transitioned .slide-animated.three {
  transition-delay: 0.9s;
  -webkit-transition-delay: 0.9s;
  -moz-transition-delay: 0.9s;
  -o-transition-delay: 0.9s;
}
.is-transitioned .slide-animated.four {
  transition-delay: 2.5s;
  -webkit-transition-delay: 2.5s;
  -moz-transition-delay: 2.5s;
  -o-transition-delay: 2.5s;
}

/*-------- 1.3 Buttons --------*/
a.btn_1,
.btn_1 {
  border: none;
  color: #fff;
  background: var(--secondary-text-color);
  outline: none;
  cursor: pointer;
  display: inline-flex;
  text-decoration: none;
  padding: 14px 25px 14px 25px;
  font-weight: 600;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
a.btn_1:hover,
.btn_1:hover {
  color: var(--secondary-text-color);
  background-color: var(--light-bg-color);
}
a.btn_1.inverse,
.btn_1.inverse {
  background-color: #fff;
  color: var(--black-text-color);
}
a.btn_1.full-width,
.btn_1.full-width {
  width: 100%;
  text-align: center;
}
a.btn_1.outline,
.btn_1.outline {
  background-color: transparent;
  border: 2px solid var(--secondary-text-color);
  color: var(--secondary-text-color);
}
a.btn_1.outline.white,
.btn_1.outline.white {
  border-color: #fff;
  color: #fff;
}
a.btn_1.outline:hover,
.btn_1.outline:hover {
  background-color: var(--secondary-text-color);
  border: 2px solid transparent;
  color: #fff;
}
a.btn_1.sm {
  font-size: 14px;
  padding: 10px 10px 10px 10px;
}
a.btn_1.reverse,
.btn_1.reverse {
  background-color: var(--black-text-color);
  opacity: 0.8;
  color: #fff;
}

.btn_4 {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.btn_4.learn-more {
  width: 190px;
  height: auto;
}
.btn_4.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 2.6rem;
  height: 2.6rem;
  background: var(--secondary-text-color);
  border-radius: 1.625rem;
}
.btn_4.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
.btn_4.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.425rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
.btn_4.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}
.btn_4.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.5rem;
  color: var(--black-text-color);
  font-weight: 600;
  line-height: 1.1;
  text-align: center;
}
.btn_4:hover .circle {
  width: 100%;
}
.btn_4:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}
.btn_4:hover .button-text {
  color: #fff;
}

a.animated_link {
  position: relative;
  text-decoration: none;
}
li.parent a.animated_link::before {
  width: calc(100% + 18px);
}
a.animated_link::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: var(--dark-bg-color);
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.3s ease;
}
a.animated_link:hover::before {
  transform: scaleX(1);
}
a.animated_link.active::before {
  transform: scaleX(1);
}

.mouse_wp {
  position: absolute;
  right: 30px;
  bottom: 30px;
  text-align: right;
  width: 40px;
  z-index: 99999;
}

.mouse {
  background: #4e5559
    linear-gradient(transparent 0%, transparent 50%, #ffffff 50%, #ffffff 100%);
  width: 36px;
  height: 58px;
  border-radius: 100px;
  background-size: 100% 200%;
  -webkit-animation: colorSlide 15s linear infinite,
    nudgeMouse 15s ease-out infinite;
  animation: colorSlide 15s linear infinite, nudgeMouse 15s ease-out infinite;
}

.mouse:before,
.mouse:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.mouse:before {
  width: 32px;
  height: 54px;
  background-color: #222a30;
  border-radius: 100px;
}

.mouse:after {
  background-color: #ffffff;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  -webkit-animation: trackBallSlide 15s linear infinite;
  animation: trackBallSlide 15s linear infinite;
}

@-webkit-keyframes colorSlide {
  0% {
    background-position: 0% 100%;
  }
  20% {
    background-position: 0% 0%;
  }
  21% {
    background-color: #4e5559;
  }
  29.99% {
    background-color: #ffffff;
    background-position: 0% 0%;
  }
  30% {
    background-color: #4e5559;
    background-position: 0% 100%;
  }
  50% {
    background-position: 0% 0%;
  }
  51% {
    background-color: #4e5559;
  }
  59% {
    background-color: #ffffff;
    background-position: 0% 0%;
  }
  60% {
    background-color: #4e5559;
    background-position: 0% 100%;
  }
  80% {
    background-position: 0% 0%;
  }
  81% {
    background-color: #4e5559;
  }
  90%,
  100% {
    background-color: #ffffff;
  }
}
@keyframes colorSlide {
  0% {
    background-position: 0% 100%;
  }
  20% {
    background-position: 0% 0%;
  }
  21% {
    background-color: #4e5559;
  }
  29.99% {
    background-color: #ffffff;
    background-position: 0% 0%;
  }
  30% {
    background-color: #4e5559;
    background-position: 0% 100%;
  }
  50% {
    background-position: 0% 0%;
  }
  51% {
    background-color: #4e5559;
  }
  59% {
    background-color: #ffffff;
    background-position: 0% 0%;
  }
  60% {
    background-color: #4e5559;
    background-position: 0% 100%;
  }
  80% {
    background-position: 0% 0%;
  }
  81% {
    background-color: #4e5559;
  }
  90%,
  100% {
    background-color: #ffffff;
  }
}
@-webkit-keyframes trackBallSlide {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  6% {
    opacity: 1;
    transform: scale(0.9) translateY(5px);
  }
  14% {
    opacity: 0;
    transform: scale(0.4) translateY(40px);
  }
  15%,
  19% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px);
  }
  28%,
  29.99% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  30% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  36% {
    opacity: 1;
    transform: scale(0.9) translateY(5px);
  }
  44% {
    opacity: 0;
    transform: scale(0.4) translateY(40px);
  }
  45%,
  49% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px);
  }
  58%,
  59.99% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  60% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  66% {
    opacity: 1;
    transform: scale(0.9) translateY(5px);
  }
  74% {
    opacity: 0;
    transform: scale(0.4) translateY(40px);
  }
  75%,
  79% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px);
  }
  88%,
  100% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
}
@keyframes trackBallSlide {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  6% {
    opacity: 1;
    transform: scale(0.9) translateY(5px);
  }
  14% {
    opacity: 0;
    transform: scale(0.4) translateY(40px);
  }
  15%,
  19% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px);
  }
  28%,
  29.99% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  30% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  36% {
    opacity: 1;
    transform: scale(0.9) translateY(5px);
  }
  44% {
    opacity: 0;
    transform: scale(0.4) translateY(40px);
  }
  45%,
  49% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px);
  }
  58%,
  59.99% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  60% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  66% {
    opacity: 1;
    transform: scale(0.9) translateY(5px);
  }
  74% {
    opacity: 0;
    transform: scale(0.4) translateY(40px);
  }
  75%,
  79% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px);
  }
  88%,
  100% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
}
@-webkit-keyframes nudgeMouse {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(8px);
  }
  30% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(8px);
  }
  60% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(8px);
  }
  90% {
    transform: translateY(0);
  }
}
@keyframes nudgeMouse {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(8px);
  }
  30% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(8px);
  }
  60% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(8px);
  }
  90% {
    transform: translateY(0);
  }
}
@-webkit-keyframes nudgeText {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(2px);
  }
  30% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(2px);
  }
  60% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(2px);
  }
  90% {
    transform: translateY(0);
  }
}
@keyframes nudgeText {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(2px);
  }
  30% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(2px);
  }
  60% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(2px);
  }
  90% {
    transform: translateY(0);
  }
}
@-webkit-keyframes colorText {
  21% {
    color: #4e5559;
  }
  30% {
    color: #ffffff;
  }
  51% {
    color: #4e5559;
  }
  60% {
    color: #ffffff;
  }
  81% {
    color: #4e5559;
  }
  90% {
    color: #ffffff;
  }
}
@keyframes colorText {
  21% {
    color: #4e5559;
  }
  30% {
    color: #ffffff;
  }
  51% {
    color: #4e5559;
  }
  60% {
    color: #ffffff;
  }
  81% {
    color: #4e5559;
  }
  90% {
    color: #ffffff;
  }
}
/*-------- 1.4 Structure --------*/
/* Header */
header {
  padding: 20px 0;
  width: 100%;
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0);
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 767px) {
  header {
    padding: 15px 0;
  }
}
header .logo_sticky {
  display: none;
}
header .logo_normal {
  display: block;
}
header.reveal_header .container-fluid {
  padding-right: 25px;
  padding-left: 25px;
}
@media (max-width: 767px) {
  header.reveal_header .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}
header.reveal_header.headroom--not-top {
  border-bottom: 1px solid #ededed;
  background-color: white;
}
header.reveal_header.headroom--top .logo_normal {
  display: block;
}
header.reveal_header.headroom--top nav ul li a {
  color: #fff;
}
header.reveal_header.headroom--top nav ul li a.animated_link:before {
  background-color: #fff;
}
header.reveal_header.headroom--top .logo_sticky {
  display: none;
}
header.reveal_header.headroom--not-top .logo_normal {
  display: none;
}
header.reveal_header.headroom--not-top .logo_sticky {
  display: block;
}
header nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: right;
  li {
    &.parent {
      padding-right: 26px;
      // + li {
      // 	@media (max-width: 1199px) {
      // 		margin-top: 15px !important;
      // 	}
      // }
      &:hover {
        &:after {
          content: "";
          position: absolute;
          left: -13px;
          top: 100%;
          width: calc(100% + 39px);
          height: 37px;
        }
        a.animated_link {
          &::before {
            transform: scaleX(1);
          }
        }
      }
      @media (max-width: 1199px) {
        &.open {
          margin-bottom: 15px !important;
          a {
            &:after {
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }
      }
      a {
        &:after {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          content: "\f107";
          font-family: "icomoon" !important;
          margin-right: -16px;
          transition: all 0.2s ease-in-out;
        }
      }
      .sub-menu li {
        a:after {
          display: none;
        }
        a.animated_link {
          &:before {
            transform: scaleX(0);
          }
          &:hover {
            &:before {
              transform: scaleX(1);
            }
          }
        }
      }
    }
    &.current {
      a {
        color: var(--secondary-text-color) !important;
        &:before {
          background-color: var(--secondary-text-color) !important;
        }
      }
    }
  }
  &.sub-menu {
    opacity: 0;
    visibility: hidden;
    background: #fff;
    position: absolute;
    padding: 8px 30px 15px 15px;
    min-width: calc(100% + 13px);
    text-align: left;
    top: calc(100% + 20px);
    left: 0;
    transform: translateY(4px);
    overflow: hidden;
    z-index: 20;
    box-shadow: 0 13px 42px 11px rgba(0, 0, 0, 0.05);
    // box-shadow: 0px 1px 10px rgba(0,0,0,0.2);

    transition-delay: 0s;
    -moz-transition: all 0s;
    -o-transition: all 0s;
    -webkit-transition: all 0s;
    -ms-transition: all 0s;
    transition: all 0s;

    @media (max-width: 1199px) {
      opacity: 1;
      position: relative;
      visibility: visible;
      transform: initial;
      top: 0;
      width: 100%;
      min-width: calc(100% + 99px);
      margin-left: -43px;
      padding: 0 30px 0 50px;

      transition-delay: 0s !important;
      -moz-transition: all 0.2s ease-in-out !important;
      -o-transition: all 0.2s ease-in-out !important;
      -webkit-transition: all 0.2s ease-in-out !important;
      -ms-transition: all 0.2s ease-in-out !important;
      transition: all 0.2s ease-in-out !important;

      box-shadow: none;
      background: #f2f2f2;
    }

    li {
      padding: 8px 8px 0 8px;
      margin: 0 !important;

      transition-delay: 0s;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;

      transform: translateY(-4px);

      opacity: 0;
      display: block;

      @media (max-width: 1199px) {
        opacity: 1;
        padding: 0 0 0 0;
        transform: inherit;
        &:first-child {
          padding-top: 16px;
        }
      }

      &:last-child {
        padding-bottom: 8px;
      }
      a {
        color: var(--black-text-color) !important;
        white-space: nowrap;
        @media (max-width: 1199px) {
          margin: 0;
        }
        &:before {
          background-color: var(--black-text-color) !important;
          left: 0 !important;
          width: 100% !important;
        }
        &:hover {
          &:before {
            left: 0 !important;
            width: 100% !important;
          }
        }
      }
      &.current {
        a {
          color: var(--secondary-text-color) !important;
          &:before {
            background-color: var(--secondary-text-color) !important;
          }
        }
      }
    }
  }
}
header:not(.small-header):not(.sticky) {
  nav ul li.current {
    a {
      color: #fff !important;
    }
    .sub-menu {
      li a {
        color: var(--black-text-color) !important;
      }
      li {
        a.animated_link {
          &:before {
            background-color: var(--black-text-color) !important;
          }
        }
      }
    }
    a.animated_link {
      &:before {
        background-color: #fff !important;
      }
    }
  }
}
header.sticky {
  nav ul.sub-menu {
    top: calc(100% + 36px);
  }
}
header nav ul li {
  display: inline-block;
  font-weight: 600;
  padding: 0 13px;
  position: relative;
  &:hover {
    .sub-menu {
      @media (max-width: 1199px) {
        transform: inherit;
      }
      transition-delay: 0.1s;
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      li {
        transition-delay: 0.1s;
        transform: translateY(0);
        opacity: 1;
        @media (max-width: 1199px) {
          transform: inherit;
        }
      }
    }
  }
}
header nav ul li:first-child {
  margin-left: 0;
}
@media (max-width: 991px) {
  header nav ul li:first-child {
    display: none;
  }
}
header.fixed_header nav ul li a {
  color: #fff;
}
header.fixed_header nav ul li a.animated_link:before {
  background-color: #fff;
}
header.fixed_header.sticky {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid #d9e1e6;
  background-color: #fff;
  height: 96px;
}
header.fixed_header.sticky .logo_normal {
  display: none;
}
header.fixed_header.sticky .logo_sticky {
  display: inline-block;
}
header.fixed_header.sticky nav ul li a {
  color: var(--black-text-color);
}
header.fixed_header.sticky nav ul li a.animated_link:before {
  background-color: var(--black-text-color);
}
header.fixed_header.sticky nav ul li a.btn_1 {
  color: #fff !important;
}
header.fixed_header.sticky .hamburger__inner,
header.fixed_header.sticky .hamburger__inner::before,
header.fixed_header.sticky .hamburger__inner::after {
  background-color: var(--black-text-color);
}

.hamburger_2 {
  position: relative;
  top: 8px;
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
@media (max-width: 1199px) {
  .hamburger_2 {
    top: 5px;
  }
}
.hamburger_2:hover .hamburger__inner {
  animation: burger 0.4s ease;
}
.hamburger_2:hover .hamburger__inner:before {
  animation: burger 0.4s 0.1s ease;
}
.hamburger_2:hover .hamburger__inner:after {
  animation: burger 0.4s 0.2s ease;
}
.hamburger_2 .hamburger__box {
  width: 24px;
  height: 24px;
  display: inline-block;
  position: relative;
}
.hamburger_2 .hamburger__inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger_2 .hamburger__inner,
.hamburger_2 .hamburger__inner::before,
.hamburger_2 .hamburger__inner::after {
  width: 24px;
  height: 2px;
  background-color: #fff;
  border-radius: 0px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger_2 .hamburger__inner::before,
.hamburger_2 .hamburger__inner::after {
  content: "";
  display: block;
}
.hamburger_2 .hamburger__inner::before {
  top: -8px;
}
.hamburger_2 .hamburger__inner::after {
  bottom: -8px;
}

@keyframes burger {
  0% {
    transform-origin: 0 100%;
    transform: scale(1, 1);
  }
  50% {
    transform-origin: 0 100%;
    transform: scale(0, 1);
  }
  100% {
    transform-origin: 0 100%;
    transform: scale(1, 1);
  }
}
.headroom--not-top .hamburger__inner,
.headroom--not-top .hamburger__inner::before,
.headroom--not-top .hamburger__inner::after {
  background-color: var(--black-text-color);
}

/* Hamburger with close */
.hamburger {
  position: relative;
  top: 8px !important;
}

.headroom--not-top .hamburger-inner,
.headroom--not-top .hamburger-inner::before,
.headroom--not-top .hamburger-inner::after {
  background-color: var(--black-text-color);
}

/*Animated header revealed*/
.animated {
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  will-change: transform, opacity;
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes slideDown {
  0% {
    -moz-transform: translateY(-100%);
  }
  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes slideDown {
  0% {
    -o-transform: translateY(-100%);
  }
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
.animated.slideDown {
  -webkit-animation-name: slideDown;
  -moz-animation-name: slideDown;
  -o-animation-name: slideDown;
  animation-name: slideDown;
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100%);
  }
}
@-moz-keyframes slideUp {
  0% {
    -moz-transform: translateY(0);
  }
  100% {
    -moz-transform: translateY(-100%);
  }
}
@-o-keyframes slideUp {
  0% {
    -o-transform: translateY(0);
  }
  100% {
    -o-transform: translateY(-100%);
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
.animated.slideUp {
  -webkit-animation-name: slideUp;
  -moz-animation-name: slideUp;
  -o-animation-name: slideUp;
  animation-name: slideUp;
}

/*-------- Menu Version 1 --------*/
.nav_panel {
  overflow-y: scroll;
  transform: translateX(100%);
  top: 0;
  right: 0;
  bottom: 0;
  width: 350px;
  height: 100%;
  position: fixed;
  z-index: 99999;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
}
.nav_panel.show {
  transform: translateX(0);
}
@media (max-width: 767px) {
  .nav_panel {
    width: 100%;
  }
}

.logo_panel {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 135px;
  background-color: var(--light-bg-color);
  img {
    max-height: 105px;
    max-width: 135px;
    width: auto;
  }
}

.sidebar-navigation {
  border-top: 1px solid #ededed;
  overflow-x: hidden;
  width: 100%;
  position: absolute;
  padding-top: 20px;
  top: 135px;
  bottom: 0;
  left: 0;
  padding-left: 20px;
  padding-right: 10px;
  z-index: 9999;
}
.sidebar-navigation nav {
  position: relative;
  height: 100%;
  min-height: 450px;
}
.sidebar-navigation nav a {
  font-size: 14px;
  font-size: 0.875rem;
  color: var(--black-text-color);
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
}
.sidebar-navigation nav a.active {
  color: var(--secondary-text-color);
}
.sidebar-navigation nav ul {
  list-style: none;
  position: absolute;
  top: 0;
  padding: 1rem;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1;
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.sidebar-navigation nav ul ul {
  right: calc(200% + 10px);
  left: calc(100% + 20px);
}
.sidebar-navigation nav ul li.parent > a:after {
  font-family: "bootstrap-icons";
  content: "\f285";
  float: right;
}

.sidebar-navigation nav ul li.back a {
  color: #999;
  text-transform: none;
  background-color: rgba(255, 255, 255, 0.2);
}

.sidebar-navigation nav ul li.back {
  margin-left: -20px;
}
.sidebar-navigation nav ul li.back > a:before {
  font-family: "bootstrap-icons";
  content: "\f284";
  margin-right: 5px;
  float: left;
}

.sidebar-navigation nav ul li.back a:focus,
.sidebar-navigation nav ul li.back a:hover {
  color: var(--black-text-color);
}

.sidebar-navigation nav ul li a {
  margin-bottom: 10px;
  padding: 0.35rem 0.65rem;
  display: block;
  position: relative;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

.sidebar-navigation nav ul li a:focus,
.sidebar-navigation nav ul li a:hover {
  color: var(--secondary-text-color);
}

.sidebar-navigation nav ul .active {
  left: 0;
  right: 0;
}
.sidebar-navigation nav ul .active > li > a {
  opacity: 1;
}

.sidebar-navigation nav ul.hidden > li > a {
  opacity: 0;
}

.sidebar-navigation nav ul ul .active {
  left: calc(100% + 20px);
  right: calc(100% + 20px);
}

.panel_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  padding: 0 25px;
  font-size: 14px;
  font-size: 0.875rem;
}

.phone_element {
  border-top: 1px solid #d9e1e6;
  padding: 20px 0 20px 0;
  position: relative;
  line-height: 1.4;
}
.phone_element a {
  display: flex;
  align-items: center;
}
.phone_element a span {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--secondary-text-color);
}
.phone_element a span em {
  color: var(--black-text-color);
  display: block;
  font-style: inherit;
  text-transform: uppercase;
  font-size: 11px;
  font-size: 0.6875rem;
  font-weight: 500;
  letter-spacing: 2px;
}
.phone_element a i {
  margin-right: 15px;
  font-size: 30px;
  font-size: 1.875rem;
  color: var(--secondary-text-color);
}
.phone_element.no_borders {
  padding-top: 5px;
  border-top: none;
}

.closebt {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  color: #ccc;
  display: inline-block;
  text-align: center;
  position: absolute;
  right: 25px;
  top: 0;
}
.closebt i {
  font-size: 42px;
  font-size: 2.625rem;
}
.closebt:hover {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  opacity: 1;
}

/*-------- Menu Version 2 --------*/
a.close_panel {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 32px;
  font-size: 2rem;
  color: #ccc;
}
a.close_panel:hover {
  color: #444;
}

.panel_menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: var(--secondary-text-color);
  overflow: hidden;
  transition: all 600ms cubic-bezier(0.19, 1, 0.56, 1);
  transform: translate3d(0, -100%, 0);
}
.panel_menu.active {
  transform: translate3d(0, 0, 0);
}
.panel_menu .panel__content {
  height: 100%;
  width: 100%;
  text-align: left;
  opacity: 0;
  transition: all 600ms;
  transition-delay: 600ms;
}
.panel_menu .panel__content .panel__content_wrapper {
  padding: 0 15px;
  background-color: #4a504c;
  background-color: rgba(75, 81, 77, 0.9);
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .panel_menu .panel__content .panel__content_wrapper {
    padding-top: 30px;
    overflow: scroll;
    display: block;
  }
}
.panel_menu .panel__content .contacts ul {
  list-style: none;
  padding: 0;
  margin: 0 0 25px 0;
}
.panel_menu .panel__content .contacts ul li a {
  display: flex;
  align-items: center;
}
.panel_menu .panel__content .contacts ul li a i {
  font-size: 24px;
  font-size: 1.5rem;
  margin-right: 10px;
}
@media (max-width: 991px) {
  .panel_menu .wrapper_menu {
    margin-top: 100px;
  }
}
.panel_menu .wrapper_menu > ul {
  list-style: none;
  margin: 0 0 25px 0;
  padding: 0;
}
.panel_menu .wrapper_menu > ul li a {
  position: relative;
  font-size: 36px;
  font-size: 2.25rem;
  font-weight: 600;
  letter-spacing: -0.03em;
  text-decoration: none;
  color: #fff;
  transition: opacity 0.3s ease-in-out;
}
@media (max-width: 767px) {
  .panel_menu .wrapper_menu > ul li a {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

.panel_menu.active .panel__content {
  opacity: 1;
}

.no_scroll {
  overflow: hidden;
}

@media (max-width: 991px) {
  .header_color {
    background-color: var(--secondary-text-color);
  }
}

.image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: all 1s ease-in-out;
}
.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  filter: grayscale(100%);
}

ul > li:first-child .image {
  opacity: 1;
  visibility: visible;
  transition-delay: 1000ms;
}

.container-item.active a + .image {
  opacity: 1;
  visibility: visible;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.wrapper_menu.hover a {
  opacity: 0.4;
}

.container-item.active a {
  opacity: 1;
}

.menu_panel_details {
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 10px;
}
.menu_panel_details h5 {
  color: #fff;
  font-size: 19px;
  font-size: 1.1875rem;
}
.menu_panel_details p {
  margin-bottom: 20px;
}
.menu_panel_details a {
  color: #fff;
}
.menu_panel_details a:hover {
  color: var(--primary-text-color);
}

/*-------- Menu v3 --------*/
.menu_v1 .hamburger_2 {
  top: 5px;
}
.menu_v1 .hamburger_2,
.menu_v1 .closebt,
.menu_v1 .layer {
  display: none;
}
@media (max-width: 1199px) {
  .menu_v1 .hamburger_2,
  .menu_v1 .closebt,
  .menu_v1 .layer {
    display: block;
  }
}
.menu_v1 .logo_panel {
  display: none;
  border-bottom: 1px solid #ededed;
}
@media (max-width: 1199px) {
  .menu_v1 .logo_panel {
    display: flex;
  }
}

@media only screen and (max-width: 1199px) {
  .main-menu {
    overflow-y: scroll;
    transform: translateX(102%);
    top: 0;
    right: 0;
    bottom: 0;
    width: 350px;
    height: 100%;
    position: fixed;
    z-index: 99999;
    background-color: #fff;
    -webkit-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    -moz-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    -ms-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    -o-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
    -moz-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  }
  .main-menu #mainNav {
    padding: 30px;
  }
  .main-menu #mainNav ul {
    text-align: left;
  }
  .main-menu #mainNav ul li {
    display: block;
    margin: 0;
  }
  .main-menu #mainNav ul li a:not(.btn_1) {
    display: block;
    color: var(--black-text-color) !important;
    text-transform: uppercase;
    padding: 0.35rem 0.65rem !important;
    margin: 0 0 10px 0;
  }
  .main-menu #mainNav ul li a:before {
    display: none !important;
  }
  .main-menu #mainNav ul li a:hover,
  .main-menu #mainNav ul li a.active {
    color: var(--secondary-text-color) !important;
  }
  .main-menu #mainNav ul li a.btn_1 {
    margin-bottom: 10px;
  }

  .main-menu.show {
    transform: translateX(0);
  }
}
.nav_panel #mainNav nav ul > li {
  display: block;
  margin: 15px 0;
  padding: 0 !important;
  opacity: 0;
  -webkit-transition: all 0.8s ease 500ms;
  -o-transition: all 0.8s ease 500ms;
  transition: all 0.8s ease 500ms;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
}

.nav_panel #mainNav nav ul > li:first-child {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.nav_panel #mainNav nav ul > li:nth-child(2) {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.nav_panel #mainNav nav ul > li:nth-child(3) {
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.nav_panel #mainNav nav ul > li:nth-child(4) {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.nav_panel #mainNav nav ul > li:nth-child(5) {
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.nav_panel.show #mainNav nav ul > li {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

/*-------- Hero --------*/
.opacity-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: all 0.5s ease;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.jarallax {
  position: relative;
  z-index: 0;
}

.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

span[data-jarallax-element] {
  display: block;
}

.kenburns .owl-item.active .item img,
img.jarallax-img.kenburns {
  transform-origin: 50% 50%;
  animation: kenburns 15s linear 0s;
  transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  animation-fill-mode: forwards;
  /* or: backwards, both, none */
}

@keyframes kenburns {
  0% {
    transform: scale3d(1.4, 1.4, 1.4) translate3d(-5%, -2%, 0px);
    transition: transform 7000ms linear 1s;
  }
  100% {
    transform: scale3d(1.25, 1.25, 1.25) translate3d(0px, 0px, 0px);
    transition: transform 7000ms linear 1s;
  }
}
img.jarallax-img.kenburns-2 {
  transform-origin: 50% 50%;
  animation: kenburns 10s linear 0s;
  transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  animation-fill-mode: forwards;
  /* or: backwards, both, none */
}

.hero {
  color: #fff;
}
.hero.full-height {
  height: 100vh;
}
@media (max-width: 991px) {
  .hero.full-height {
    min-height: 600px;
  }
}
@media (max-width: 767px) {
  .hero.full-height {
    height: 87vh;
  }
}
.hero.medium-height {
  height: 70vh;
  min-height: 490px;
}
@media (max-width: 991px) {
  .hero.medium-height {
    min-height: 600px;
  }
}
.hero.small-height {
  height: 30vh;
  min-height: 490px;
}
@media (max-width: 991px) {
  .hero.small-height {
    min-height: 600px;
  }
}
main.small-header {
  background: #fff;
  padding-top: 96px;
  .hero.small-height {
    height: auto;
    min-height: auto;
    &.video-bg-container {
      .wrapper {
        height: auto;
        position: relative;
        padding: 2rem 0;
        min-height: 300px;
        h1,
        h3 {
          margin-bottom: 40px;
        }
      }
    }

    &:not(.video-bg-container) {
      .wrapper {
        height: auto;
        position: relative;
        padding: 2rem 0;
        background-color: #f5f5f5 !important;
        border-bottom: 1px solid #eee;
        text-align: left !important;
        h1 {
          color: var(--black-text-color);
        }
        h1,
        h3 {
          margin-bottom: 0;
        }
        small {
          color: var(--primary-text-color);
        }
      }
    }
  }
  &.with-image {
    .hero {
      .wrapper {
        background-color: rgba(0, 0, 0, 0.5) !important;
        small {
          margin-bottom: 0;
          padding-left: 5px;
        }
        h1 {
          color: #fff;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .hero.small-height {
      min-height: auto;
    }
  }
}
.hero .wrapper {
  text-align: left;
  height: 100%;
}
.hero .wrapper small {
  text-transform: uppercase;
  letter-spacing: 3px;
  display: block;
  margin: 0 0 10px 0;
  color: var(--primary-text-color);
  font-weight: 600;
}
.hero .wrapper h1,
.hero .wrapper h3 {
  color: #fff;
  font-size: 51px;
  font-size: 3.1875rem;
  margin: 0 0 10px 0;
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .hero .wrapper h1,
  .hero .wrapper h3 {
    font-size: 36px;
    font-size: 2.25rem;
  }
}
@media (max-width: 575px) {
  .hero .wrapper h1,
  .hero .wrapper h3 {
    font-size: 32px;
    font-size: 2rem;
  }
}
.hero .wrapper h6 {
  margin-top: -5px;
  margin-bottom: 15px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.2px;
  color: #fff;
}
.hero .wrapper p {
  font-weight: 400;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.4;
  margin-bottom: 0;
}
.hero .wrapper p strong {
  font-weight: 600;
}
@media (max-width: 767px) {
  .hero .wrapper p {
    padding: 0;
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.parallax_section_1 {
  height: 70vh;
}
@media (max-width: 991px) {
  .parallax_section_1 {
    min-height: 650px;
  }
}

/*-------- Footer --------*/
footer {
  background-color: var(--dark-bg-color);
  position: relative;
  color: #fff;
}
footer .footer_bg {
  position: relative;
  // padding-top: 230px;
  padding-top: 135px;
  z-index: 2;
  overflow: hidden;
  &.bg {
    // padding-top: 160px;
    padding-top: 135px;
  }
}
@media (max-width: 767px) {
  footer .footer_bg {
    height: 160px;
    padding-top: 180px;
  }
}
footer .footer_bg .background-image {
  opacity: 0.3;
  filter: grayscale(100%);
}
footer .footer_bg .gradient_over {
  background: transparent;
  background: -webkit-linear-gradient(top, transparent, var(--dark-bg-color));
  background: linear-gradient(to bottom, transparent, var(--dark-bg-color));
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 3;
}
footer .move_content {
  margin-top: -80px;
  position: relative;
  z-index: 4;
  // padding-bottom: 120px;
  padding-bottom: 15px;
  &.bg {
    padding-bottom: 30px;
  }
}
footer .footer-logo {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  footer .move_content {
    padding-bottom: 60px;
    &.bg {
      padding-bottom: 15px;
    }
  }
}
footer h5 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 20px;
  font-size: 1.25rem;
}
@media (max-width: 767px) {
  footer h5 {
    margin-bottom: 10px;
  }
}
footer hr {
  opacity: 0.1;
}
footer ul {
  list-style: none;
  padding: 0;
  margin: 0 0 25px 0;
}
footer a {
  color: var(--primary-text-color);
}
footer a:hover {
  color: #fff;
}
footer .copy {
  text-align: center;
  padding: 25px 0;

  background-color: rgba(#000, 0.3);
  font-size: 13px;
  font-size: 0.8125rem;

  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--dark-bg-color);
    z-index: -1;
  }
}

.social ul {
  display: block;
  list-style: none;
  margin: 0 0 25px 0;
  padding: 0;
}
.social ul li {
  display: inline-block;
  margin: 0 5px;
}
.social ul li a {
  display: block;
  color: #fff;
}
.social ul li a:hover {
  color: var(--primary-text-color);
}
.social ul li a i {
  font-size: 21px;
  font-size: 1.3125rem;
}

.styled-select {
  max-width: 180px;
  overflow: hidden;
  height: 40px;
  line-height: 40px;
  border: none;
  background-color: #f3f3f3;
  background-color: rgba(255, 255, 255, 0.05);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding-right: 35px;
  position: relative;
}
.styled-select.lang-selector:after {
  font-family: "bootstrap-icons";
  content: "\F3EE";
  color: #fff;
  position: absolute;
  right: 15px;
  top: 0;
}
.styled-select select {
  background: transparent;
  width: 110%;
  padding-left: 15px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  height: 30px;
  color: #ccc;
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 0;
  font-weight: 500;
  -moz-appearance: window;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
}
.styled-select select:focus {
  color: #fff;
  outline: none;
  box-shadow: none;
}
.styled-select select::-ms-expand {
  display: none;
}

.styled-select.lang-selector select option {
  color: #555;
}

.footer_links {
  margin-bottom: 25px;
}
.footer_links ul {
  line-height: 1.9;
}
.footer_links ul li a {
  display: inline-block;
  color: #fff;
}
.footer_links ul li a:hover {
  transform: translateX(10px);
}

#newsletter .form-group {
  position: relative;
}
#newsletter h5 {
  margin: 0 0 25px 0;
}
@media (max-width: 767px) {
  #newsletter h5 {
    margin: 0 0 15px 0;
  }
}
#newsletter .form-group {
  position: relative;
}
#newsletter input[type="email"] {
  border: 0;
  height: 45px;
  border-radius: 3px;
  padding-left: 15px;
  background-color: #f3f3f3;
  background-color: rgba(255, 255, 255, 0.06);
  color: #fff;
}
#newsletter input[type="email"]:focus {
  border: 0;
  box-shadow: none;
}
#newsletter button[type="submit"] {
  position: absolute;
  right: 5px;
  color: #fff;
  font-size: 22px;
  font-size: 1.375rem;
  top: 2px;
  border: 0;
  opacity: 0.6;
  height: 45px;
  cursor: pointer;
  background-color: transparent;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
}
#newsletter button[type="submit"]:hover {
  opacity: 1;
}

/* Opacity mask when left open */
.layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.35);
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  transition: all 0.1s ease;
  opacity: 0;
  visibility: hidden;
}

.layer-is-visible {
  opacity: 1;
  visibility: visible;
}

/*============================================================================================*/
/* 2.  CONTENT */
/*============================================================================================*/
/*-------- 2.1 Home page --------*/
.booking_form {
  background-color: #fff;
  padding: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  margin: 15px auto 0;
  position: relative;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
}
@media (max-width: 991px) {
  .booking_form {
    padding: 0;
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
.booking_form input {
  border: 0;
  height: 50px;
  padding-left: 15px;
  border-radius: 0;
  background-color: transparent;
  border-right: 1px solid #d9e1e6;
  font-weight: 500;
  font-size: 15px;
  font-size: 0.9375rem;
  color: #6c757d;
}
@media (max-width: 991px) {
  .booking_form input {
    border: none;
    background-color: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
}
.booking_form input:focus {
  box-shadow: none;
  border-right: 1px solid #d9e1e6;
}
@media (max-width: 991px) {
  .booking_form input:focus {
    border-right: none;
  }
}
.booking_form .form-group {
  margin: 0;
  position: relative;
}
@media (max-width: 991px) {
  .booking_form .form-group {
    margin-bottom: 5px;
  }
}
.booking_form .form-group i {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-size: 21px;
  font-size: 1.3125rem;
  position: absolute;
  line-height: 50px;
  top: 2px;
  right: 4px;
  padding-right: 15px;
  display: block;
  width: 20px;
  box-sizing: content-box;
  height: 50px;
  z-index: 1;
  color: var(--secondary-text-color);
}
.booking_form input[type="submit"] {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  font-size: 0.9375rem;
  border: 0;
  height: 50px;
  cursor: pointer;
  outline: none;
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background-color: var(--secondary-text-color);
  margin-right: 5px;
}
@media (max-width: 991px) {
  .booking_form input[type="submit"] {
    margin: 20px 0 0 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }
}
.booking_form input[type="submit"]:hover {
  background-color: var(--secondary-text-color);
  color: #fff;
}

/* Quantity incrementer input */
.qty-buttons {
  position: relative;
  width: 100%;
  display: inline-block;
}
.qty-buttons label {
  position: absolute;
  color: #999;
  left: 15px;
  top: 13px;
  font-weight: 500;
  color: #6c757d;
  font-size: 15px;
  font-size: 0.9375rem;
}
.qty-buttons input.qty {
  width: 100%;
  text-align: left;
  padding-left: 80px;
}
.qty-buttons input.qtyminus,
.qty-buttons input.qtyplus {
  position: absolute;
  width: 36px;
  height: 36px;
  border: 0;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  text-indent: -9999px;
  box-shadow: none;
  border-radius: 50%;
  top: 8px;
}
.qty-buttons input.qtyplus {
  background: #f5f5f5 url(../images/plus.svg) no-repeat center center;
  right: 15px;
}
.qty-buttons input.qtyminus {
  background: #f5f5f5 url(../images/minus.svg) no-repeat center center;
  right: 55px;
}
.qty-buttons.version_2 .form-control {
  height: 50px;
}
.qty-buttons.version_2 input.qty {
  padding-left: 15px;
}
.qty-buttons.version_2 input.qtyplus {
  background: #fff url(../images/plus.svg) no-repeat center center;
  right: 5px;
}
.qty-buttons.version_2 input.qtyminus {
  background: #fff url(../images/minus.svg) no-repeat center center;
  right: 40px;
}

/*-------- Carousel Home Page --------*/
#carousel-home {
  position: relative;
}
#carousel-home .static {
  position: static;
}
#carousel-home .cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  max-height: none !important;
  z-index: 2;
  opacity: 1 !important;
}
#carousel-home .owl-carousel {
  position: relative;
}
#carousel-home .owl-carousel .owl-slide {
  height: 100vh;
  position: relative;
  &.medium-height {
    height: 60vh;
  }
  &.small-height {
    height: 30vh;
    min-height: 600px;
  }
}
@media (max-width: 767px) {
  #carousel-home .owl-carousel .owl-slide {
    height: 90vh;
    &.medium-height {
      height: 50vh;
    }
    &.small-height {
      height: 20vh;
      min-height: 400px;
    }
  }
}
body.boxed-header {
  #carousel-home .owl-carousel .owl-slide {
    height: calc(100vh - 96px);
    &.medium-height {
      height: calc(60vh - 96px);
    }
    &.small-height {
      height: calc(30vh - 96px);
    }
  }
  @media (max-width: 767px) {
    #carousel-home .owl-carousel .owl-slide {
      height: calc(90vh - 96px);
      &.medium-height {
        height: calc(50vh - 96px);
      }
      &.small-height {
        height: calc(20vh - 96px);
      }
    }
  }
}
#carousel-home .owl-carousel .owl-slide-animated {
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -ms-transform: translateY(20px);
  -o-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
  visibility: hidden;
  -moz-transition: all 0.05s ease;
  -o-transition: all 0.05s ease;
  -webkit-transition: all 0.05s ease;
  -ms-transition: all 0.05s ease;
  transition: all 0.05s ease;
}
#carousel-home .owl-carousel .owl-slide-animated.is-transitioned {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  opacity: 1;
  visibility: visible;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
#carousel-home .owl-carousel .owl-slide-title.is-transitioned {
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
}
#carousel-home .owl-carousel .owl-slide-title-2.is-transitioned {
  transition-delay: 0.4s;
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
}
#carousel-home .owl-carousel .owl-slide-title-3.is-transitioned {
  transition-delay: 0.6s;
  -webkit-transition-delay: 0.6s;
  -moz-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
}
#carousel-home .owl-carousel .owl-slide-title-4.is-transitioned {
  transition-delay: 0.8s;
  -webkit-transition-delay: 0.8s;
  -moz-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
}
#carousel-home .owl-carousel .owl-dots .owl-dot,
#carousel-home .owl-carousel .owl-nav [class*="owl-"]:focus {
  outline: none;
}

.owl-carousel .owl-dots {
  margin-top: 0 !important;
  right: 15px;
  width: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 3;
}

.owl-carousel.nav-dots-orizontal .owl-dots {
  margin-top: 20px !important;
  width: 100%;
  position: static;
}
.slide-text h1,
.slide-text h2,
.slide-text h3 {
  font-size: 51px;
  font-size: 3.1875rem;
  text-transform: uppercase;
  font-weight: 700;
}
.slide-text p {
  font-size: 21px;
  font-size: 1.3125rem;
}
@media (max-width: 767px) {
  .slide-text h1,
  .slide-text h2,
  .slide-text h3 {
    font-size: 42px;
    font-size: 2.625rem;
  }
}
@media (max-width: 499px) {
  .slide-text h1,
  .slide-text h2,
  .slide-text h3 {
    font-size: 34px;
    font-size: 2.125rem;
  }
  .slide-text p {
    font-size: 17px;
    font-size: 1.1125rem;
  }
}
.slide-text small {
  text-transform: uppercase;
  color: var(--primary-text-color);
  letter-spacing: 4px;
  font-weight: 600;
  display: block;
  margin: 0 0 10px 0;
}

.slide-text.white {
  color: #fff;
}
.slide-text.white h1,
.slide-text.white h2,
.slide-text.white h3 {
  color: #fff;
}
.slide-text.black {
  color: #111;
}
.slide-text.black h2 {
  color: #000;
}
.slide-text.black h1 {
  color: #000;
}

#icon_drag_mobile {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: url(../images/drag_icon.svg) 0 0 no-repeat;
  width: 50px;
  height: 30px;
  opacity: 0.6;
  z-index: 99;
  display: none;
}
@media (max-width: 767px) {
  #icon_drag_mobile {
    display: block;
  }
}

a.box_cat_rooms {
  display: block;
  margin-bottom: 25px;
  height: 500px;
  position: relative;
  overflow: hidden;
  background-color: #ccc;
  border-radius: 0;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
}
@media (max-width: 991px) {
  a.box_cat_rooms {
    height: 400px;
  }
}
a.box_cat_rooms .info {
  position: absolute;
  width: 100%;
  z-index: 9;
  display: block;
  padding: 25% 30px 0 30px;
  color: #fff;
  left: 0;
  bottom: -75px;
  padding-bottom: 60px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: transparent;
  background: -webkit-linear-gradient(top, transparent, #000);
  background: linear-gradient(to bottom, transparent, #000);
}
a.box_cat_rooms .info small {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  color: #999;
  display: block;
  margin-bottom: 0;
  font-size: 12px;
  font-size: 0.75rem;
  color: #fff;
}
a.box_cat_rooms .info h3 {
  color: #fff;
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 600;
  margin-bottom: 15px;
}
a.box_cat_rooms .info span {
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  opacity: 0;
  position: relative;
}
a.box_cat_rooms .info span:before {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 10px;
  height: 2px;
  content: "";
  transition: width 500ms ease;
  background-color: #fff;
}
a.box_cat_rooms .info span:hover {
  background-color: var(--dark-bg-color);
  border-color: transparent;
}
a.box_cat_rooms figure {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
a.box_cat_rooms:hover .info {
  -webkit-transform: translate(0, -65px);
  -moz-transform: translate(0, -65px);
  -ms-transform: translate(0, -65px);
  -o-transform: translate(0, -65px);
  transform: translate(0, -65px);
}
a.box_cat_rooms:hover .info span {
  opacity: 1;
}
a.box_cat_rooms:hover .info span:before {
  width: 100%;
}
a.box_cat_rooms:hover .background-image {
  -ms-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

/*-------- 2.2 About  --------*/
.box_facilities {
  padding: 0 25px 25px 25px;
  border-width: 2px;
  text-align: center;
  border-style: solid;
  border-right: 0;
  border-image: linear-gradient(to bottom, #ededed, transparent) 1 60%;
}
@media (max-width: 1199px) {
  .box_facilities {
    border-width: 0;
  }
}
.box_facilities i {
  font-size: 62px;
  font-size: 3.875rem;
  color: var(--secondary-text-color);
}
.box_facilities h3 {
  font-size: 21px;
  font-size: 1.3125rem;
}
@media (max-width: 767px) {
  .box_facilities h3 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.box_facilities.no-border {
  border-width: 0;
}
.box_facilities.white {
  border-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.2),
      transparent
    )
    1 60%;
}
.box_facilities.white h3 {
  color: #fff;
}
.box_facilities.white i {
  color: var(--primary-text-color);
}
@media (max-width: 767px) {
  .box_facilities.white {
    padding: 0;
    margin-bottom: 25px;
  }
  .box_facilities.white i {
    font-size: 62px;
    font-size: 3.875rem;
  }
}

/* Testimonials */
.owl-carousel .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}

.box_overlay {
  background-color: var(--dark-bg-color);
  background-color: var(--dark-bg-color);
  padding: 45px 45px 45px 225px;
  min-height: 170px;
  color: #fff;
  position: relative;
  margin-bottom: 20px;
  border-radius: 0;
}
@media (max-width: 767px) {
  .box_overlay {
    padding: 30px;
  }
}

.pic {
  position: absolute;
  left: 45px;
  top: 45px;
  text-align: left;
}
@media (max-width: 767px) {
  .pic {
    position: static;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
}
.pic h4 {
  position: absolute;
  left: 95px;
  top: 18px;
  color: #fff;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
}
@media (max-width: 767px) {
  .pic h4 {
    position: static;
  }
}
.pic h4 small {
  display: block;
  color: #fff;
  margin-top: 3px;
  letter-spacing: normal !important;
}
.pic figure {
  width: 80px;
  height: 80px;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .pic figure {
    margin: 0 auto 10px;
  }
}
.pic figure img {
  width: 80px;
  height: auto;
}

.comment {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  padding-left: 30px;
  font-size: 18px;
  font-size: 1.125rem;
  font-style: italic;
  text-align: left;
}
@media (max-width: 767px) {
  .comment {
    text-align: center;
    border: 0;
    padding: 0;
  }
}

/* Accordion */
.accordion .card {
  border: 0;
  margin-bottom: 10px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.accordion .card .card-header {
  background-color: #fff;
  padding: 20px;
  border: 0;
}
.accordion .card-body {
  padding: 0 20px;
}
.accordion .card-header h5 {
  font-size: 17px;
  font-size: 1.0625rem;
  color: var(--black-text-color);
}
.accordion .card-header h5 a {
  border: 0;
  display: block;
  color: var(--black-text-color);
}
.accordion .card-header h5 a i.indicator {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: normal;
  float: right;
  color: var(--black-text-color);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.accordion .card-header h5 a.collapsed i {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

/*-------- 2.3 Room list --------*/
.box_item_info {
  background-color: #fff;
  z-index: 9;
  position: relative;
  padding: 40px 40px 20px 40px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
.box_item_info small {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  display: block;
  margin-bottom: 0;
  font-size: 14px;
  font-size: 0.875rem;
  color: var(--secondary-text-color);
}
.box_item_info h2 {
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: 600;
  margin-bottom: 20px;
}
.box_item_info .facilities ul {
  list-style: none;
  padding: 0;
  margin: 0 0 15px 0;
}
.box_item_info .facilities ul li {
  float: left;
  display: flex;
  align-items: center;
  line-height: 1;
  margin: 0 25px 10px 0;
}
.box_item_info .facilities ul li:last-child {
  margin: 0;
}
.box_item_info .facilities ul li i {
  height: 36px;
  font-size: 30px;
  font-size: 1.875rem;
  color: var(--secondary-text-color);
  margin-right: 15px;
}
@media (max-width: 767px) {
  .box_item_info .facilities ul li {
    float: none;
    width: 100%;
  }
}
.box_item_info .box_item_footer {
  border-top: 2px solid #ededed;
  margin-top: 15px;
  padding: 25px 0 15px 0;
}
.box_item_info .box_item_footer a.animated_link {
  color: var(--black-text-color);
}

.row_list_version_1 {
  margin-bottom: 60px;
}
.row_list_version_1 .box_item_info {
  max-width: 750px;
  margin: -250px 60px 60px 60px;
}
@media (max-width: 767px) {
  .row_list_version_1 .box_item_info {
    margin: -150px 10px 0 10px;
    padding: 20px 25px 10px 25px;
  }
}

.row_list_version_2 {
  margin-bottom: 120px;
}
@media (max-width: 767px) {
  .row_list_version_2 {
    margin-bottom: 70px;
  }
}
.row_list_version_2 .owl-carousel .owl-dots {
  right: auto;
  left: 15px;
}
.row_list_version_2 .box_item_info {
  margin: 0 0 0 -100px;
}
@media (max-width: 1199px) {
  .row_list_version_2 .box_item_info {
    margin: -150px 30px 0 30px;
  }
}
@media (max-width: 767px) {
  .row_list_version_2 .box_item_info {
    margin: -50px 10px 0 10px;
    padding: 20px 25px 10px 25px;
  }
}
.row_list_version_2.inverted .owl-carousel .owl-dots {
  left: inherit;
  right: 15px;
}
.row_list_version_2.inverted .box_item_info {
  margin: 0 -100px 0 0;
}
@media (max-width: 1199px) {
  .row_list_version_2.inverted .box_item_info {
    margin: -50px 30px 0 30px;
  }
}
@media (max-width: 767px) {
  .row_list_version_2.inverted .box_item_info {
    margin: -50px 10px 0 10px;
    padding: 20px 25px 10px 25px;
  }
}

.row_list_version_3 {
  margin-bottom: 60px;
}
.row_list_version_3 .box_item_info {
  max-width: 750px;
  margin: -100px 60px 60px 60px;
}
@media (max-width: 767px) {
  .row_list_version_3 .box_item_info {
    margin: -40px 10px 0 10px;
    padding: 20px 25px 10px 25px;
  }
}

.booking_wrapper {
  border-radius: 0;
  background-color: #89795d;
  background-color: rgba(151, 134, 103, 0.05);
  padding: 20px 20px 0 20px;
}
.booking_wrapper hr {
  border-color: #d9e1e6;
  opacity: 1;
  margin: 0 0 25px 0;
}
.booking_wrapper .form-control::-moz-placeholder {
  color: var(--black-text-color);
}
.booking_wrapper .form-control::placeholder {
  color: var(--black-text-color);
}

.owl-carousel.border-radius .owl-item {
  border-radius: 0;
  overflow: hidden;
}

/*-------- 2.4 Room details --------*/
.room_facilities_list ul {
  margin: 0 0 25px 0;
  padding: 0;
  list-style: none;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 25px;
}
.room_facilities_list ul li {
  float: left;
  width: calc(50% - 25px);
  margin: 0;
  padding: 10px 0;
  border-bottom: 1px solid #ededed;
  display: flex;
  align-items: center;
  line-height: 1.1;
  @media (max-width: 767px) {
    width: 100%;
  }
  span {
  }
  strong {
    margin-left: auto;
    margin-top: -1px;
    padding-left: 10px;
  }
}
.room_facilities_list ul li i {
  font-size: 34px;
  font-size: 2.125rem;
  margin-right: 15px;
  color: var(--secondary-text-color);
}
.room_facilities_list {
  // margin-bottom: 95px;
  // @media (max-width: 767px) {
  // 	margin-bottom: 65px;
  // }
  &.single {
    ul li {
      width: 100%;
      @media (max-width: 991px) {
        width: calc(50% - 25px);
      }
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
  .attribute-comment {
    margin-left: 5px;
    font-size: 18px;
    color: var(--secondary-text-color);
  }
}
.progress {
  margin-bottom: 0;
  height: 5px;
}

.progress-bar {
  background-color: var(--secondary-text-color);
  width: 1px;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
  -webkit-transition-delay: 1s;
  -moz-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.reviews_sum_details {
  margin-bottom: 25px;
}
.reviews_sum_details h6 {
  font-size: 16px;
  font-size: 1rem;
}
.reviews_sum_details strong {
  position: relative;
  top: -10px;
}

.review_card {
  border-radius: 0;
  padding: 25px 25px 1px 25px;
  margin-bottom: 30px;
  background-color: #89795d;
  background-color: rgba(151, 134, 103, 0.05);
}
.review_card .user_info {
  text-align: center;
}
@media (max-width: 767px) {
  .review_card .user_info {
    margin-bottom: 10px;
  }
}
.review_card .user_info figure {
  width: 50px;
  height: 50px;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .review_card .user_info figure {
    float: left;
  }
}
.review_card .user_info figure img {
  width: 50px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.review_card .user_info h5 {
  font-size: 13px;
  font-size: 0.8125rem;
}
@media (max-width: 767px) {
  .review_card .user_info h5 {
    float: left;
    margin: 18px 0 0 15px;
  }
}
.review_card .review_content h4 {
  font-size: 18px;
  font-size: 1.125rem;
}
.review_card .review_content .rating {
  float: left;
  font-weight: 600;
  font-size: 26px;
  font-size: 1.625rem;
  color: var(--secondary-text-color);
}
.review_card .review_content .rating small {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 600;
}
.review_card .review_content .rating strong {
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 400;
  color: var(--black-text-color);
}
@media (max-width: 575px) {
  .review_card .review_content .rating {
    float: none;
    display: block;
  }
}
.review_card .review_content .rating em {
  font-size: 14px;
  font-size: 0.875rem;
}
@media (max-width: 575px) {
  .review_card .review_content .rating em {
    float: none;
    display: inline-block;
    margin-left: 10px;
  }
}
.review_card .review_content em {
  font-size: 13px;
  font-size: 0.8125rem;
  padding-top: 12px;
  color: #777;
  float: right;
}
@media (max-width: 575px) {
  .review_card .review_content em {
    float: none;
    display: block;
    margin-top: 10px;
    margin-left: 0;
  }
}
.review_card .review_content ul {
  margin: 35px 0 25px 0;
  padding: 0;
}
.review_card .review_content ul li {
  display: inline-block;
  font-size: 13px;
  font-size: 0.8125rem;
  margin-right: 0;
}
@media (max-width: 575px) {
  .review_card .review_content ul li span {
    display: none;
  }
}
.review_card .review_content ul li a {
  background-color: #f0f0f0;
  padding: 7px 10px 5px 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  color: var(--black-text-color);
}
.review_card .review_content ul li a:hover {
  background-color: var(--dark-bg-color);
  color: #fff;
}
.review_card .review_content ul li a i {
  margin-right: 5px;
}
@media (max-width: 575px) {
  .review_card .review_content ul li a i {
    margin-right: 0;
  }
}
.review_card .review_content ul li:last-child {
  float: right;
  margin-right: 0;
}
.review_card .reply {
  padding-top: 30px;
}
@media (max-width: 767px) {
  .review_card .reply .user_info {
    display: none;
  }
}
.review_card .reply .user_info figure {
  width: 50px;
  height: 50px;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-bottom: 10px;
}
.review_card .reply .user_info figure img {
  width: 50px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.review_card .reply .user_info h5 {
  font-size: 14px;
  font-size: 0.875rem;
}
.review_card .reply .review_content {
  border-left: 3px solid #ddd;
  padding-left: 20px;
}
@media (max-width: 575px) {
  .review_card .reply .review_content strong {
    display: block;
  }
}
.review_card .reply .review_content em {
  color: #999;
  font-style: italic;
  float: right;
  position: relative;
  top: -10px;
}
@media (max-width: 575px) {
  .review_card .reply .review_content em {
    float: none;
    display: block;
    margin-top: 10px;
  }
}

/* hover effect 
  .carousel_item {
	  .item {
		   img {
				  @include transform (scale(1));
				  transition: all 2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
			  }
  
			  &:hover img {
				  @include transform (scale(1.1));
			  }
	  }
  }
  */
/* ------ 2.5 News page ----------- */
a.box_contents {
  margin: 0 10px 45px 10px;
  display: block;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  overflow: hidden;
  background-color: #fff;
}
a.box_contents .wrapper {
  padding: 20px 40px 50px 60px;
  position: relative;
}
a.box_contents figure {
  position: relative;
  overflow: hidden;
  -moz-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  -webkit-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  transition: all 0.7s ease;
}
a.box_contents figure img {
  transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
a.box_contents figure em {
  position: absolute;
  z-index: 4;
  bottom: 0;
  left: 10px;
  background-color: var(--dark-bg-color);
  color: #fff;
  padding: 10px 15px;
  line-height: 1;
  text-transform: uppercase;
  font-style: normal;
  font-size: 12px;
  font-size: 0.75rem;
}
a.box_contents small {
  font-weight: 600;
  color: #ddd;
  text-transform: uppercase;
  position: absolute;
  top: 5px;
  left: 40px;
  display: block;
  width: 150%;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  white-space: nowrap;
  position: absolute;
  letter-spacing: 2px;
}
a.box_contents small:after {
  background-color: #eee;
  width: 100%;
  height: 1px;
  content: "";
  display: inline-block;
  margin: 4px;
}
a.box_contents h2 {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 30px;
}
a.box_contents em {
  font-style: normal;
  font-weight: 600;
  position: relative;
}
a.box_contents em:before {
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 10px;
  height: 2px;
  content: "";
  transition: width 500ms ease;
  background-color: var(--secondary-text-color);
}

a.box_contents:hover em:before {
  width: 100%;
  background-color: var(--black-text-color);
}

a.box_contents:hover img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.home a.box_contents {
  margin: 0 0 25px 0;
}

.news-wrapper {
  &.home {
    margin-top: 60px;
  }
  div.item {
    margin-bottom: 45px;
  }
  a.box_contents {
    margin-bottom: 0;
    height: 100%;
    .wrapper {
      padding: 0 40px 25px 60px;
      &.no-cat {
        padding: 0 40px 25px 40px;
      }
      h2 {
        font-size: 19px;
      }
    }
  }
}

#comments {
  margin-bottom: 25px;
}
#comments ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#comments ul li {
  padding: 25px 0 0 0;
  list-style: none;
}
#comments .replied-to {
  margin-left: 35px;
}
@media (max-width: 767px) {
  #comments .replied-to {
    margin-left: 20px;
  }
}

.avatar {
  float: left;
  margin-right: 25px;
  width: 68px;
  height: 68px;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  position: relative;
}
@media (max-width: 767px) {
  .avatar {
    float: none;
    margin: 0 0 5px 0;
  }
}
.avatar img {
  width: 68px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.comment_right {
  display: table;
  background-color: #f7f7f7;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 20px 20px 0 20px;
  position: relative;
}

.comment_right:after,
.comment_right:before {
  right: 100%;
  top: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.comment_right:after {
  background-color: transparent;
  border-right-color: #f7f7f7;
  border-width: 15px;
  margin-top: -15px;
}

.comment_right:before {
  border-color: transparent;
  border-width: 16px;
  margin-top: -16px;
}

.comment_info {
  padding-bottom: 7px;
}
.comment_info span {
  padding: 0 10px;
}

/*-------- 2.6 Contact page --------*/
.contact_info {
  background-color: white;
  border-radius: 0;
  padding: 30px 45px 20px 45px;
  margin-bottom: 25px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  @media (max-width: 499px) {
    padding: 30px 25px 20px 25px;
  }
}
.bg_white .contact_info {
  background-color: var(--light-bg-color) !important;
}
.contact_info ul {
  list-style: none;
  padding: 0;
  margin: 0 0 0 0;
}
.contact_info ul li {
  margin-bottom: 25px;
}
.contact_info span[class^="amicon-"],
.contact_info span[class*=" amicon-"] {
  font-size: 36px;
  font-size: 2.25rem;
  color: var(--secondary-text-color);
  @media (max-width: 499px) {
    float: left;
    margin-right: 15px;
  }
}
.contact_info ul li h4 {
  font-size: 21px;
  font-size: 1.3125rem;
  @media (max-width: 499px) {
    font-size: 19px;
    font-size: 1.1125rem;
    margin: 0 0 0 0;
    padding: 8px 0 0 0;
  }
}
.contact_info ul li small {
  font-style: oblique;
  opacity: 0.8;
}
.contact_info p {
  margin-bottom: 0;
  @media (max-width: 499px) {
    margin-top: 20px;
  }
}
.map_contact iframe {
  width: 100%;
  filter: grayscale(85%);
  height: 450px;
  margin-bottom: -10px;
}

/*-------- 2.7 Error page --------*/
.error_page {
  height: 100vh;
}
.error_page h1 {
  font-size: 90px;
  font-size: 5.625rem;
}

/*-------- 2.8 Gallery --------*/
.item-img {
  position: relative;
  border-radius: 0;
  overflow: hidden;
}
.item-img img {
  max-width: 100%;
}
.item-img .content {
  position: absolute;
  text-align: center;
  height: 0;
  width: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.65);
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transition: 0.2s ease-in-out;
  opacity: 0;
}
.item-img .content a {
  opacity: 0;
  transition: 0.2s ease;
  font-size: 24px;
  width: 100%;
  height: 100%;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item:hover .content {
  height: calc(100% - 30px);
  width: calc(100% - 30px);
  opacity: 1;
}

.item:hover .content a {
  opacity: 1;
  color: #fff;
}

/*============================================================================================*/
/* 3.  COMMON */
/*============================================================================================*/
/*-------- 3.1 Misc --------*/
.custom_badge {
  line-height: 1;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  margin-left: 5px;
  display: inline-block;
  border-radius: 3px;
  background-color: red;
  font-size: 11px;
  font-size: 0.6875rem;
  padding: 3px 5px;
  position: relative;
  top: -1px;
}

/* Carousel Centered */
.carousel_item_centered .owl-item,
.carousel_item_centered_rooms .owl-item {
  opacity: 0.5;
  transform: scale(0.85) !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(0.85, 0.85) !important;
  transition: all 0.3s ease-in-out 0s;
  overflow: hidden;
}

.carousel_item_centered .owl-item.active.center,
.carousel_item_centered_rooms .owl-item.active.center {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1) !important;
  transform: scale(1) !important;
}

/* Marquee */
.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 100px;
  overflow: hidden;
  font-size: 120px;
  font-weight: bold;
  margin-top: -60px;
}
.marquee .track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 140s linear infinite;
}
.marquee .content {
  opacity: 0.05;
  line-height: 1.2;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-30%);
  }
}
.modal-content {
  border: none !important;
  border-radius: 0 !important;
  padding: 25px;
}

//   .focus-block, .text-sticky, .std {
// 	ul {
// 		list-style: none;
// 		padding: 0;
// 		margin: 0 0 50px 0;
// 	  }
// 	  ul li {
// 		padding-left: 45px;
// 		position: relative;
// 		margin-bottom: 25px;
// 		font-size: 15px;
// 		font-size: 0.9375rem;
// 		line-height: 1.6;
// 		min-height: 25px;
// 		p {
// 			margin-bottom: 0;
// 		}
// 	  }
// 	  ul li strong {
// 		color: var(--black-text-color);
// 	  }
// 	  ul li:before {
// 		color: var(--secondary-text-color);
// 		position: absolute;
// 		left: 0;
// 		top: -7px;
// 		font-family: "bootstrap-icons";
// 		content: "\F19F";
// 		font-size: 32px;
// 		font-size: 2rem;
// 	  }
//   }

.focus-block,
.text-sticky,
.std {
  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 50px 0;
  }
  ul li {
    padding-left: 25px;
    position: relative;
    margin-bottom: 25px;
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.6;
    min-height: 25px;
    p {
      margin-bottom: 0;
    }
  }
  ul li strong {
    color: var(--black-text-color);
  }
  ul li:before {
    position: absolute;
    left: 0;
    top: 7px;
    content: "•";
    line-height: 1px;
    font-size: 45px;
  }
}

/* Pinned content */
.pinned-image {
  display: flex;
  height: 90vh;
  justify-content: center;
  position: relative;
  overflow: hidden;
  &.medium-height {
    max-height: 750px;
  }
  &.small-height {
    max-height: 500px;
  }
}
.pinned-image.rounded_container {
  border-radius: 0;
}

.pinned-image--medium {
  height: 75vh;
}

.pinned-image--small {
  height: 45vh;
}

.pinned-image__container {
  position: relative;
  transform: scale(0.8);
  width: 100%;
  border-radius: 0;
  overflow: hidden;
}

.pinned-image__container-overlay {
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.pinned-image__container img,
.pinned-image__container video,
.pinned-image__container {
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #ccc;
}

.pinned_over_content {
  text-align: center;
  padding: 0 60px;
  width: 100%;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
@media (max-width: 767px) {
  .pinned_over_content {
    padding: 0 15px;
    h2 {
      font-size: 2.3rem;
    }
  }
}
@media (max-width: 499px) {
  .pinned_over_content {
    h2 {
      font-size: 2.1rem;
    }
  }
}

.pinned_over_content h2 {
  color: #fff;
  font-size: 52px;
  font-size: 3.25rem;
  font-weight: 700;
  letter-spacing: -0.03em;
  line-height: 1.2;
  @media (max-width: 767px) {
    font-size: 2.5rem;
  }
}

.parallax_wrapper {
  position: relative;
  margin-bottom: 25px;
  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
}
@media (max-width: 991px) {
  .parallax_wrapper {
    padding-left: 25%;
  }
}
.parallax_wrapper .img_over {
  left: -25%;
  bottom: 10%;
  position: absolute;
  z-index: 99;
}
@media (max-width: 991px) {
  .parallax_wrapper .img_over {
    left: 0;
  }
}

.invert-parallax .parallax_wrapper .img_over {
  right: -75%;
  left: auto;
  bottom: 10%;
  position: absolute;
  z-index: 99;
}
@media (max-width: 991px) {
  .invert-parallax .parallax_wrapper .img_over {
    left: auto;
    right: 0;
  }
}
.parallax_wrapper .img_over img {
  border: 4px solid #fff;
  width: 50%;
  max-width: 655px;
  height: auto;
  -webkit-box-shadow: 10px 10px 26px 0px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 10px 10px 26px 0px rgba(0, 0, 0, 0.21);
  box-shadow: 10px 10px 26px 0px rgba(0, 0, 0, 0.21);
}
@media (max-width: 991px) {
  .parallax_wrapper.inverted {
    padding-left: inherit;
    padding-right: 25%;
  }
}
.parallax_wrapper.inverted .img_over {
  text-align: right;
  left: inherit;
  right: -25%;
}
@media (max-width: 991px) {
  .parallax_wrapper.inverted .img_over {
    left: inherit;
    right: 0;
  }
}

img.rounded-img {
  border-radius: 0;
}

.bg_gray {
  background-color: #f4f4f4;
}

.bg_color_1 {
  background-color: var(--secondary-text-color);
}

.bg_white {
  background-color: #fff;
}
.bg_classic {
  background-color: var(--light-bg-color) !important;
}
.pattern {
  background: url(../images/pattern.png) top left no-repeat;
  background-attachment: fixed;
}

.bg_gray.pattern {
  background: #f4f4f4 url(../images/pattern.png) top left no-repeat;
}
.bg_gray.pattern.bg_fixed {
  background-attachment: fixed;
}

.pattern_2 {
  background: #fff url(../images/pattern_2.png) top right no-repeat;
}
@media (max-width: 767px) {
  .pattern_2 {
    background-size: 80% auto;
  }
}

/*-------- 3.2 Datepicker --------*/
.daterangepicker {
  border: 0 !important;
  font-family: var(--webfont) !important;
}

.daterangepicker .calendar-table th {
  font-size: 13px !important;
}

.daterangepicker .calendar-table td {
  width: 40px !important;
  height: 35px !important;
  font-size: 13px !important;
  font-weight: 500;
}

/* Fix for scroll issue */
@media screen and (min-width: 560px) {
  .daterangepicker.single {
    width: auto !important;
  }
}
.applyBtn.btn.btn-sm.btn-primary {
  background-color: var(--secondary-text-color);
  border: 0;
}

.drp-selected {
  display: none !important;
}

@media screen and (max-width: 600px) {
  .daterangepicker .drp-calendar {
    max-width: 100% !important;
  }

  .daterangepicker .drp-calendar.left,
  .daterangepicker .drp-calendar.right {
    clear: none !important;
    float: none !important;
    margin-right: 0;
  }
}
.daterangepicker {
  -webkit-box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
  box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
}

/* ------ Full view calendar on mobile ----------- */
@media (max-width: 575px) {
  .datepicker_mobile_full .daterangepicker {
    margin-top: 0 !important;
    border: none !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    z-index: 9999999 !important;
    height: 100% !important;
    overflow-y: scroll !important;
  }
  .datepicker_mobile_full .daterangepicker .calendar {
    max-width: 100% !important;
  }
}

/*-------- 3.3 Forms --------*/
.nice-select {
  font-weight: 400 !important;
}

#message-newsletter {
  font-size: 14px;
  font-size: 0.875rem;
  padding-bottom: 5px;
}

.error_message {
  color: red;
}

.form-group {
  margin-bottom: 25px;
}
.form-group label {
  font-weight: 500;
  margin-bottom: 3px;
}

.form-control {
  border-color: #ededed;
  font-size: 14px;
  font-size: 0.875rem;
  color: var(--black-text-color);
  height: 50px;
}
.form-control:focus {
  border-color: #ddd;
  outline: 0;
  box-shadow: 0 0 0 0.2rem transparent;
}

textarea.form-control {
  height: 130px !important;
}

/*-------- 3.4 Success submit --------*/
#success_page {
  padding: 30px 0;
  text-align: center;
  font-size: 24px;
  font-size: 1.5rem;
}
#success_page b,
#success_page strong {
  margin-top: 15px;
  letter-spacing: -0.03em;
  line-height: 1.3;
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 7px;
  display: block;
}
#success_page p {
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1;
  margin-top: 15px;
}

#confirm {
  text-align: center;
  padding: 60px 15px;
}

@-webkit-keyframes checkmark {
  0% {
    stroke-dashoffset: 50px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-ms-keyframes checkmark {
  0% {
    stroke-dashoffset: 50px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes checkmark {
  0% {
    stroke-dashoffset: 50px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }
  100% {
    stroke-dashoffset: 480px;
  }
}
@-ms-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }
  100% {
    stroke-dashoffset: 480px;
  }
}
@keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }
  100% {
    stroke-dashoffset: 480px;
  }
}
.inlinesvg .svg svg {
  display: inline;
}

.icon--order-success svg path {
  -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
  animation: checkmark 0.25s ease-in-out 0.7s backwards;
}

.icon--order-success svg circle {
  -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
  animation: checkmark-circle 0.6s ease-in-out backwards;
}

/*-------- 3.5 Progess Scroll to top --------*/
.progress-wrap {
  position: fixed;
  right: 30px;
  bottom: 30px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  z-index: 99993;
  opacity: 0;
  display: none;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
}
@media (max-width: 991px) {
  .progress-wrap {
    display: none !important;
  }
}
.progress-wrap.active-progress {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.progress-wrap::after {
  position: absolute;
  font-family: "bootstrap-icons";
  content: "\F145";
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  color: #999;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap:hover::after {
  color: var(--secondary-text-color);
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap svg.progress-circle path {
  stroke: var(--secondary-text-color);
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

/*-------- 3.6 Spacing --------*/
.margin_120_95 {
  padding-top: 120px;
  padding-bottom: 95px;
}
@media (max-width: 767px) {
  .margin_120_95 {
    padding-top: 90px;
    padding-bottom: 65px;
  }
}

.margin_95_95 {
  padding-top: 95px;
  padding-bottom: 95px;
}
@media (max-width: 767px) {
  .margin_95_95 {
    padding-top: 65px;
    padding-bottom: 65px;
  }
}

.margin_120_0 {
  padding-top: 120px;
}

.margin_120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.margin_60_35 {
  padding-top: 60px;
  padding-bottom: 35px;
}
@media (max-width: 575px) {
  .margin_60_35 {
    padding-top: 30px;
    padding-bottom: 5px;
  }
}

.margin_80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 991px) {
  .margin_80 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}

.margin_80_55 {
  padding-top: 80px;
  padding-bottom: 55px;
}
@media (max-width: 991px) {
  .margin_80_55 {
    padding-top: 60px;
    padding-bottom: 35px;
  }
}

.margin_80_0 {
  padding-top: 80px;
}
@media (max-width: 991px) {
  .margin_80_0 {
    padding-top: 60px;
  }
}
@media (max-width: 575px) {
  .margin_80_0 {
    padding-top: 45px;
  }
}
.margin_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.margin_60_0 {
  padding-top: 60px;
}
@media (max-width: 991px) {
  .margin_60_0 {
    padding-top: 60px;
  }
}
@media (max-width: 575px) {
  .margin_60_0 {
    padding-top: 45px;
  }
}

.add_bottom_60 {
  padding-bottom: 60px;
}

.add_bottom_120 {
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .add_bottom_120 {
    padding-bottom: 90px;
  }
}

.add_bottom_90 {
  padding-bottom: 90px;
}
@media (max-width: 767px) {
  .add_bottom_90 {
    padding-bottom: 60px;
  }
}

.add_top_90 {
  padding-top: 90px;
}
@media (max-width: 767px) {
  .add_top_90 {
    padding-top: 60px;
  }
}

.negative_95 {
  margin-bottom: -95px;
}
@media (max-width: 767px) {
  .negative_95 {
    margin-bottom: -65px;
  }
}

/*-------- 3.7 Pagination --------*/
.clear:after,
.pagination:after,
.clear:before,
.pagination:before {
  content: "";
  display: table;
}
.clear:after,
.pagination:after {
  clear: both;
}

.pagination__wrapper {
  height: 50px;
  padding-top: 30px;
  margin-bottom: 30px;
  position: relative;
  text-align: center;
  width: 100%;
}
.pagination__wrapper:before {
  top: -1px;
}
.pagination__wrapper:after {
  bottom: -1px;
}

@keyframes hoverAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.pagination {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}
.pagination li {
  display: block;
  float: left;
  padding: 5px;
}
.pagination li:first-child {
  border: none;
}
.pagination a,
.pagination span {
  background: none;
  border: none;
  border-radius: 50%;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.6);
  display: block;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  min-width: 40px;
  padding: 0;
}
.pagination a {
  outline: none;
  position: relative;
  transition: all 170ms linear;
}
.pagination a:before {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  content: "";
  cursor: pointer;
  height: 0;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: all 270ms linear;
  top: 50%;
  width: 0;
}
.pagination a:hover:not(.active) {
  color: black;
}
.pagination a:hover:not(.active):before {
  animation: hoverAnimation 810ms linear forwards;
  height: 40px;
  width: 40px;
}
.pagination .active {
  background: var(--dark-bg-color);
  color: white;
  cursor: default;
}
.pagination .prev,
.pagination .next {
  font-size: 28px;
}

.focus-block.bg_classic + .focus-block.bg_classic,
.focus-block.bg_white + .focus-block.bg_white {
  margin-top: -90px;
}
@media (max-width: 767px) {
  .focus-block.bg_classic + .focus-block.bg_classic,
  .focus-block.bg_white + .focus-block.bg_white {
    padding-bottom: -60px;
  }
}

/* FIX MARGINS */
[class^="margin_"],
[class*=" margin_"],
[class^="add_bottom_"],
[class*=" add_bottom_"] {
  &.bg_white {
    + .bg_white[class*=" margin_"] {
      padding-top: 0;
    }
    + .bg_white[class^="margin_"] {
      padding-top: 0;
    }
    + .bg_white[class^="add_bottom_"] {
      padding-top: 0;
    }
    + .bg_white[class*=" add_bottom_"] {
      padding-top: 0;
    }
    + .bg_white[class^="add_top_"] {
      padding-top: 0;
    }
    + .bg_white[class*=" add_top_"] {
      padding-top: 0;
    }
  }
  &.bg_classic {
    + .bg_classic[class*=" margin_"] {
      padding-top: 0;
    }
    + .bg_classic[class^="margin_"] {
      padding-top: 0;
    }
    + .bg_classic[class^="add_bottom_"] {
      padding-top: 0;
    }
    + .bg_classic[class*=" add_bottom_"] {
      padding-top: 0;
    }
    + .bg_classic[class^="add_top_"] {
      padding-top: 0;
    }
    + .bg_classic[class*=" add_top_"] {
      padding-top: 0;
    }
  }
}

.std {
  iframe {
    max-width: 100%;
  }
  h2,
  h3,
  h4,
  h5,
  p,
  ul,
  figure,
  blockquote {
    padding-left: 8%;
    padding-right: 8%;
  }
  img.cms-full-width {
    margin-left: -8%;
    margin-right: -8%;
    min-width: 116%;
  }
  figure {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    img {
      &.self-left {
        margin-right: 1.5em;
        float: left;
      }
      &.self-right {
        margin-right: 1.5em;
        float: right;
      }
    }
  }
  ul {
    li {
      margin-left: 1rem;
    }
  }
  p {
    &:after {
      content: " ";
      display: block;
      clear: both;
    }
  }
  blockquote {
    p {
      padding: 0 0 0 15px;
    }
    margin-top: 40px;
    position: relative;
    font-size: 1.4em;
    font-style: italic;
    font-weight: 300;
    &:before {
      position: absolute;
      font-family: "icomoon", sans-serif;
      font-size: 3.5rem;
      opacity: 0.1;
      left: 8%;
      top: -45px;
    }
    &:before {
      content: "\f10d";
    }
  }
}
.recaptcha_field {
  opacity: 0;
  width: 1px;
  position: absolute;
  left: -9999px;
}
.form-block-content small {
  font-size: 11px;
  display: block;
  margin-top: 10px;
}
.form-row-error {
  color: red;
  font-size: 11px;
  font-weight: 600;
  padding-left: 15px;
  display: block;
  margin-top: 8px;
}
.form-general-error {
  font-size: 12px;
  color: red;
  font-weight: 600;
  display: block;
  margin-bottom: 20px;
}
#accept_cg-error {
  margin-bottom: 8px;
  padding-left: 0;
}
.cgv-block {
  label {
    margin: 0;
  }
  input {
    margin-right: 20px;
  }
  p {
    font-size: 11px;
    margin-bottom: 0;
  }
}
.breadcrumb-container {
  position: relative;
}
.boxed-breadcrumb {
  .breadcrumb-container {
    padding-left: 8%;
    padding-right: 8%;
    .breadcrumb {
      position: relative;
      top: 0;
      margin-top: var(--bs-breadcrumb-margin-bottom);
    }
  }
}
.breadcrumb {
  position: absolute;
  left: 0;
  top: var(--bs-breadcrumb-margin-bottom);
  li {
    a {
      font-weight: 500;
    }
  }
}
.headless-post {
  padding-top: 65px;
  margin-bottom: -50px;
  small {
    text-transform: uppercase;
    letter-spacing: 3px;
    display: block;
    margin: 0 0 0 0;
    color: var(--primary-text-color);
    font-weight: 600;
  }
  h1 {
    font-size: 3.1875rem;
    margin: 0 0 10px 0;
    font-weight: 700;
    text-transform: uppercase;
  }
  h6 {
    margin-top: -5px;
    margin-bottom: 15px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.2px;
  }
  .breadcrumb {
    position: relative;
    top: 0;
    justify-content: center;
  }
}

.admin-preview {
  [data-cue] {
    opacity: 1 !important;
  }
  [data-cue="zoomIn"],
  [data-cues="zoomIn"] > * {
    opacity: 1 !important;
  }
  [data-cue="slideInLeft"],
  [data-cues="slideInLeft"] > *,
  [data-cue="slideInRight"],
  [data-cues="slideInRight"] > * {
    opacity: 1 !important;
  }
  [data-cue="slideInUp"],
  [data-cues="slideInUp"] > * {
    opacity: 1 !important;
  }
  .pinned-image {
    min-height: 400px;
  }
  .pinned-image__container {
    transform: scale(1);
  }
  .owl-carousel {
    display: flex !important;
    gap: 12px;
    img {
      max-width: 100%;
    }
    > div + div {
      display: none;
    }
  }
  .hr-separator {
    margin-top: 0;
    margin-bottom: 0;
    padding: 60px 0 60px 0;
  }
  img.jarallax-img.kenburns-2 {
    animation: none;
  }
  .opacity-mask {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.gallery-items {
  .owl-carousel .owl-item {
    .item-image {
      display: block;
      width: 100%;
      height: 475px;
      overflow: hidden;
      .img {
        width: 100%;
        height: 100%;
        display: block;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    img {
      width: auto;
      max-height: 365px;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

.privacy-box-cookies {
  background: #fff;
  position: fixed;
  right: 20px;
  bottom: 20px;
  max-width: 350px;
  z-index: 99992;
  padding: 30px;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px;

  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  transition-delay: 0.5s;

  &.accepted {
    transform: translateX(120%);
    opacity: 0;
    ul li {
      span {
        color: var(--secondary-text-color);
        &:before {
          content: "\f058";
        }
      }
      &:before {
        content: "";
        background: var(--secondary-text-color);
        position: absolute;
        left: -12px;
        top: 0;
        height: calc(100% + 4px);
        width: 5px;
      }
    }
  }
  p {
    font-size: 14px;
    line-height: 1.2;
    margin: 0 0 18px 0;
    font-weight: 500;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    padding-left: 12px;
    position: relative;
    &:before {
      content: "";
      background: #d3d3d3;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 5px;
    }
    li {
      margin: 0;
      padding: 0;
      position: relative;
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 4px;
      span {
        margin-right: 8px;
        font-size: 20px;
        color: #d3d3d3;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
      &:before {
        content: "";
        background: #d3d3d3;
        position: absolute;
        left: -12px;
        top: 0;
        height: calc(100% + 4px);
        width: 5px;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
      &.active {
        span {
          color: var(--secondary-text-color);
        }
        &:before {
          content: "";
          background: var(--secondary-text-color);
          position: absolute;
          left: -12px;
          top: 0;
          height: calc(100% + 4px);
          width: 5px;
        }
      }
    }
  }
  a {
    margin-right: 8px;
  }
}

.cookie-headband {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99992;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--secondary-text-color);
    opacity: 0.9;
    z-index: -1;
  }
  p {
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
  a {
    font-size: 14px;
    margin-left: 10px !important;
    color: #fff;
    &:not(.btn_1) {
      text-decoration: underline;
    }
  }
}

.widEXC.cx_brand_refresh {
  margin: 0 !important;
}

.carousel_testimonials {
  .box_overlay {
    padding: 45px;
  }
  .pic {
    max-width: 150px;
    min-width: 150px;
    position: relative;
    left: 0;
    top: 0;
    figure {
      min-width: 40px;
      min-height: 40px;
      max-width: 40px;
      max-height: 40px;
      height: auto;
      width: auto;
    }
    h4 {
      margin-left: 15px;
      hyphens: auto;
      position: relative;
      left: 0;
      top: 0;
    }
  }
  .comment {
    margin-left: 30px;
    padding-left: 30px;
    .rating {
      display: block;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
      @media (max-width: 499px) {
        font-size: 40px;
      }
    }
    .text {
      @media (max-width: 767px) {
        display: -webkit-box !important;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        white-space: normal;
        overflow: hidden;
      }
    }
  }
}
@media (max-width: 767px) {
  .carousel_testimonials {
    .box_overlay {
      flex-direction: column;
    }
    .pic {
      flex-wrap: wrap;
      max-width: 100%;
      min-width: 100%;
      margin-bottom: 30px;
      h4 {
        width: 100%;
        min-width: 100%;
        margin: 0;
      }
    }
    .comment {
      margin-left: 0;
      padding-left: 0;
    }
  }
}

.booking-toolbar {
  background: var(--booking-bar-color);
  width: 100%;
  height: 70px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9992;
  transition: all 0.3s ease-in-out;
  transform: translateY(100%);
  @media (max-width: 991px) {
    .container {
      max-width: 100%;
    }
  }
  &.sticky {
    transform: translateY(0);
    .currency {
      opacity: 1;
    }
  }
  .booking-toolbar-container {
    padding: 5px 0;
  }
  div > small {
    color: #fff;
    font-size: 10px;
    letter-spacing: 0.35px;
    line-height: 11px;
    margin-bottom: 7px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-weight: 500;
  }
  .date-sm {
    display: none;
    background: #fff;
    align-items: center;
    justify-content: center;
    padding: 10px 20px 10px 20px;
    font-weight: 600;
    border-radius: 3px;
    height: 100%;
    width: 46px;
    margin: 0 auto;
    span {
      color: var(--booking-bar-color);
      font-size: 30px;
    }
  }
  .booking-toolbar-cal {
    small,
    strong {
      transition: all 0.3s ease-in-out;
    }
    &.loading {
      position: relative;
      small,
      strong {
        opacity: 0.1;
      }
      &:after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        left: 50%;
        margin-left: -10px;
        margin-top: -10px;
        animation: circle infinite 0.95s linear;
        border: 2px solid #fff;
        border-top-color: rgba(255, 255, 255, 0.2);
        border-right-color: rgba(255, 255, 255, 0.2);
        border-bottom-color: rgba(255, 255, 255, 0.2);
        border-radius: 100%;
      }
    }
  }
  .start-date {
    height: 60px;
    cursor: pointer;
    border-right: 1px solid hsla(0, 0%, 100%, 0.15);
    padding: 7px 15px;
    max-width: 140px;
    width: 50%;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    &:hover {
      opacity: 0.7;
    }
    strong {
      font-size: 17px;
      color: #fff;
      font-weight: 400;
    }
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  .end-date {
    height: 60px;
    cursor: pointer;
    border-right: 1px solid hsla(0, 0%, 100%, 0.15);
    padding: 7px 15px;
    max-width: 140px;
    width: 50%;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    &:hover {
      opacity: 0.7;
    }
    strong {
      font-size: 17px;
      color: #fff;
      font-weight: 400;
    }
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  @media (max-width: 649px) {
    .start-date {
      padding-right: 0;
      border-right: 0;
      padding-left: 0;
      min-width: 65px;
      width: auto;
      small {
        display: none;
      }
    }
    .end-date {
      display: none;
    }
    .date-sm {
      display: flex;
    }
    #booking_toolbar_cal_start {
      display: none;
    }
  }
  .persons {
    height: 60px;
    border-right: 1px solid hsla(0, 0%, 100%, 0.15);
    padding: 7px 15px;
    max-width: 140px;
    width: 50%;
    text-align: center;
    text-transform: uppercase;
    margin-right: 7px;
    .qty {
      strong {
        font-size: 17px;
        color: #fff;
        font-weight: 400;
        width: 40px;
        text-align: center;
      }
      a {
        display: block;
        background-color: hsla(0, 0%, 100%, 0.1);
        color: #fff;
        font-weight: 200;
        font-size: 10px;
        border-radius: 4px;
        width: 22px;
        height: 22px;
        line-height: 25px;
      }
    }
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  .best-price {
    height: 60px;
    border-right: 1px solid hsla(0, 0%, 100%, 0.15);
    padding: 7px 0 7px 0;
    max-width: 140px;
    width: 50%;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    display: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      opacity: 0.7;
    }
    &.large {
      max-width: 185px;
      padding-right: 7px;
    }
    &.loaded {
      display: block;
    }
    &.loading {
      #booking_price_label {
        opacity: 0;
      }
      [data-loader="circle-side"] {
        opacity: 1;
      }
    }
    small {
      i {
        font-size: 12px;
        display: inline-block;
        margin-left: 5px;
      }
    }
    @media (max-width: 479px) {
      border-right: none;
    }
    #booking_price_label {
      opacity: 1;
      font-size: 20px;
      color: #fff;
      font-weight: 600;
      transition: opacity 0.3s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: none;
      &.smaller {
        font-size: 16px;
        span {
          font-size: 12px;
        }
      }
      span {
        text-transform: uppercase;
        font-size: 12px;
        margin-right: 10px;
        @media (max-width: 767px) {
          display: none;
        }
      }
    }
    [data-loader="circle-side"] {
      transition: all 0.3s ease-in-out;
      width: 30px;
      height: 30px;
      margin-left: -15px;
      margin-top: -15px;
      border: 2px solid var(--black-text-color);
      border-top-color: #fff;
      border-right-color: #fff;
      border-bottom-color: #fff;
      top: auto;
      bottom: 7px;
      margin-top: 0;
      opacity: 0;
    }
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  .currency {
    transition: all 0.3s ease-in-out;
    display: none;
    position: absolute;
    top: -60px;
    opacity: 0;
    right: 10px;
    label {
      font-size: 12px;
      text-transform: uppercase;
    }
    .nice-select {
      padding-right: 40px;
      font-size: 12px;
      .option {
        line-height: 34px;
        min-height: 34px;
      }
    }
    &.active {
      display: block;
    }
    small {
      position: absolute;
      top: -8px;
      z-index: 10;
      left: 12px;
      color: var(--black-text-color);
      padding: 3px;
      font-weight: 600;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 8px;
        background: #fff;
        left: 0;
        width: 100%;
        z-index: -1;
      }
    }
    // ul {
    // 	position: absolute;
    // 	top: -35px;
    // 	width: 100%;
    // 	left: 0;
    // 	list-style: none;
    // 	margin: 0;
    // 	padding: 0;
    // 	background: var(--black-text-color);
    // 	li {
    // 		color: #FFF;
    // 		font-weight: 600;
    // 		height: 30px;
    // 		line-height: 30px;
    // 	}
    // }
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  .price-btn {
    height: 60px;
    padding: 7px 0 7px 20px;
    position: relative;
    a {
      color: var(--booking-bar-color);
      background: #fff;
      padding: 10px 20px 10px 20px;
      font-weight: 600;
      border-radius: 3px;
      display: flex;
      align-items: center;
      height: 100%;
      white-space: nowrap;
      &:hover {
        opacity: 0.8;
      }
      span {
        margin-right: 8px;
        @media (max-width: 798px) {
          display: none;
        }
      }
    }
    @media (max-width: 479px) {
      position: absolute;
      top: -68px;
      right: 15px;
      a {
        color: #fff;
        background: var(--booking-bar-color);
      }
    }
  }
  .best-price-box {
    position: absolute;
    bottom: 70px;
    background-color: #fff;
    border-radius: 4px 4px 0 0;
    box-shadow: 8px 8px 40px 5px rgba(0, 0, 0, 0.08);
    z-index: 1;
    width: 500px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    visibility: hidden;
    &.loaded {
      visibility: visible;
    }
    &.closed {
      height: 0 !important;
    }
    @media (max-width: 320px) {
      width: 300px;
    }
    .inner {
      padding: 30px;
    }
    p {
      font-size: 13px;
      line-height: 1.3;
    }
    .prices-official {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
      color: var(--secondary-text-color);
      font-size: 20px;
      line-height: 1.2;
      font-weight: 500;
      margin-bottom: 4px;
      padding: 0 0 4px 0;
      border-bottom: 1px solid rgba(16, 17, 17, 0.1);
      .name {
        small {
          font-size: 11px;
          margin-top: 5px;
          color: var(--black-text-color);
          display: block;
          margin-bottom: 4px;
        }
      }
    }
    .prices-other {
      .price {
        display: flex;
        justify-content: space-between;
        padding: 5px 0 0 0;
        span {
          text-transform: uppercase;
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
          color: #808787;
        }
        .name {
          padding-right: 10px;
        }
      }
    }
    .infos-price {
      margin-top: 25px;
      h4 {
        color: var(--secondary-text-color);
        font-size: 16px;
      }
      ul {
        margin-top: 20px;
        padding: 0;
        li {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          align-items: flex-start;
          margin-bottom: 5px;
          i {
            color: var(--secondary-text-color);
            margin-right: 8px;
            font-size: 16px;
          }
          span {
            font-size: 13px;
            line-height: 1.3;
            font-weight: 500;
          }
        }
      }
    }
  }
  #booking_date_picker {
    opacity: 0;
    position: absolute;
    left: -999px;
    width: 0;
  }
  #datepicker-booking_date_picker.loading {
    .datepicker__inner {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        animation: circle infinite 0.95s linear;
        border: 2px solid #3858e9;
        border-top-color: #f3f3f3;
        border-right-color: #f3f3f3;
        border-bottom-color: #f3f3f3;
        border-radius: 100%;
      }
    }
  }
  .datepicker {
    bottom: 70px;
    border-radius: 4px 4px 0 0;
    width: 780px;
    @media (max-width: 768px) {
      width: 600px;
      .datepicker__month--month2 {
        display: none;
      }
    }
    @media (max-width: 589px) {
      width: 100%;
    }
    .cal-legend {
      padding: 10px 20px 20px 20px;
      font-size: 12px;
      font-style: italic;
      .alert {
        margin-top: -20px;
      }
      &.nload {
        opacity: 0;
      }
      div {
        display: block;
        margin-bottom: 10px;
      }
      .last-available {
        font-style: normal;
        display: flex;
        align-items: center;
        margin-bottom: 7px;
        &:before {
          content: "";
          margin-right: 8px;
          display: block;
          width: 0;
          height: 0;
          border-top: 8px solid red;
          border-left: 8px solid transparent;
        }
      }
      .non-available {
        font-style: normal;
        display: flex;
        align-items: center;
        margin-bottom: 7px;
        color: #bfbfbf;
      }
    }
    .datepicker__month-day--disabled:after {
      content: "";
    }
    .datepicker__inner {
      padding: 20px 0;
    }
    .datepicker__topbar {
      display: none !important;
    }
    .datepicker__month {
      width: calc(50% - 40px) !important;
      @media (max-width: 768px) {
        width: calc(100% - 40px) !important;
      }
    }
    .datepicker__month--month1 {
      margin-left: 20px;
    }
    .datepicker__month--month2 {
      margin-right: 20px;
    }
    .datepicker__months:before {
      display: none;
    }
    .datepicker__month-day--no-checkout:before {
      z-index: 1;
    }
    .datepicker__month-day--nextMonth {
      display: none;
    }
    .datepicker__month-day {
      background: #f3f3f3;
      border: solid 2px #fff;
      height: 55px;
      font-size: 11px;
      font-weight: 400;
      vertical-align: top;
      position: relative;
      &.datepicker__month-day--valid {
        &:after {
          content: "" !important;
          position: absolute !important;
          left: 0 !important;
          top: 0 !important;
          width: 100% !important;
          height: 100% !important;
          z-index: 10 !important;
        }
      }
      &.datepicker__month-day--selected,
      &.datepicker__month-day--hovering,
      &.datepicker__month-day--first-day-selected {
        &:before {
          border: none !important;
          content: "" !important;
          position: absolute !important;
          left: 0 !important;
          top: 0 !important;
          width: 100% !important;
          height: 100% !important;
          z-index: 10 !important;
        }
      }
      &:first-line {
        font-weight: 600;
        font-size: 13px;
        line-height: 1.3;
        display: block;
      }
      span {
        font-size: 11px;
        display: block;
        font-weight: 400;
        position: absolute;
        bottom: 12px;
        left: 0;
        max-width: 100%;
        width: 100%;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        padding: 0 4px;
        &:after {
          content: attr(data-price);
        }
      }
      &.datepicker__month-day--last-day-selected {
        span {
          &:after {
            content: attr(data-hover);
          }
        }
      }
      &:hover {
        background: #d2d2d2;
        &.datepicker__month-day--selected,
        &.datepicker__month-day--first-day-selected {
          background-color: #3858e9;
          color: #fff !important;
        }
      }
    }
    .datepicker__month-day--invalid {
      color: #bfbfbf;
      cursor: default;
      &:not(.datepicker__month-day--with-extra) {
        vertical-align: middle;
      }
      &:hover {
        background: #d2d2d2 !important;
        color: #000 !important;
      }
    }
    .datepicker__month-name {
      text-transform: none;
      font-size: 16px;
    }
    .datepicker__month-caption {
      border-bottom: none;
      th {
        padding-bottom: 20px;
      }
    }
    .datepicker__week-name {
      text-transform: none;
      font-weight: 600;
      padding-bottom: 8px;
      font-size: 13px;
    }
    .datepicker__month-day--today {
      color: inherit;
    }
    .datepicker__month-day--selected {
      background-color: #3858e9 !important;
    }
    .datepicker__month-day--hovering {
      &:hover {
        &.datepicker__month-day--valid {
          span {
            &:after {
              content: attr(data-hover);
            }
          }
        }
      }
      background-color: #3858e9 !important;
    }
    .datepicker__month-day--first-day-selected,
    .datepicker__month-day--last-day-selected {
      background-color: #3858e9 !important;
      color: #fff !important;
    }
    .datepicker__tooltip {
      background-color: #000;
      color: #fff;
    }
    .datepicker__tooltip:after {
      border-top: 4px solid #000;
    }
    .datepicker__month-day.last-availability {
      &:not(.datepicker__month-day--invalid) {
        position: relative;
        &:before {
          border-left: 8px solid transparent;
          border-top: 8px solid red;
          content: "";
          display: block;
          height: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 0;
          z-index: 1;
        }
      }
    }
  }
}

.dp-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0;
  bottom: 0;
  background-color: var(--dark-bg-color);
  z-index: 9991 !important;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.datapicker-open {
  .dp-loading {
    opacity: 0.8;
    height: 100%;
  }
}

.flex-table {
  display: flex;
  width: 100%;
  .flex-table-row {
    display: flex;
    flex-direction: column;
    width: 20%;
    text-align: left;
    &.first {
      width: 100%;
      max-width: 40%;
    }
    .flex-table-col {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 45px 0 1;
      padding: 5px;
      text-align: center;
      &:nth-child(2n) {
        background: #fafafa;
      }
      &.first {
        justify-content: flex-start;
      }
      &.header {
        flex: 50px 0 0;
      }
    }
  }
}
#pop_modal {
  .modal-content {
    border-radius: 0 !important;
    padding: 0 !important;
    .modal-body {
      overflow: hidden;
      position: relative;
      padding: 25px !important;
      .btn-close {
        position: absolute;
        z-index: 10;
        right: 25px;
        top: 15px;
      }
      &.style-half-right {
        display: flex;
        justify-content: end;
      }
      &.style-half-left {
        display: flex;
        justify-content: start;
      }
      .text {
        position: relative;
        z-index: 10;
        border-radius: 0;
        padding: 15px 25px;
        margin-top: 20px;
        h3 {
          font-size: 31px;
          font-size: 1.7375rem;
          margin-bottom: 15px;
        }
        p {
          font-size: 0.9375rem;
          line-height: 1.6;
          margin-bottom: 0;
          a {
            margin-top: 25px;
          }
        }
      }
      .style-full {
        width: 100%;
        background: rgba(255, 255, 255, 0.95);
      }
      .style-half {
        width: 50%;
        background: rgba(255, 255, 255, 0.95);
      }
      .bg-image {
        border-radius: 0;
        overflow: hidden;
        position: absolute;
        z-index: 8;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}
.inline-video {
  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.video-boxed {
  min-height: 45vh;
  overflow: hidden;
  border-radius: 0;
  .video-bg {
    background: white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    /* overflow: hidden; */
  }
  .video-bg .video-fg,
  .video-bg iframe,
  .video-bg video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  /*
		Full page video background
		Simulate object-fit: cover
		Based on http://fvsch.com/code/video-background/
	  */
  @media (min-aspect-ratio: 16/9) {
    .video-bg.cover .video-fg {
      height: 300%;
      top: -100%;
    }
  }
  @media (max-aspect-ratio: 16/9) {
    .video-bg.cover .video-fg {
      width: 300%;
      left: -100%;
    }
  }
  @supports ((-o-object-fit: cover) or (object-fit: cover)) {
    .video-bg.cover .video-fg.supports-cover {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .video-bg.cover video {
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
  /*
		Vertical centering for 16/9 youtube iframes and video elements
		Simulate object-fit: contain for entire element, not just contents of element
	  */
  .video-bg.contain {
    font-size: 0;
  }
  .video-bg.contain * {
    font-size: 16px;
  }
  .video-bg.contain:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  .video-bg.contain .video-fg {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    /* 16:9 */
    pointer-events: none;
  }
  .video-bg.contain iframe,
  .video-bg.contain video {
    pointer-events: auto;
  }
  @media (min-aspect-ratio: 16/9) {
    .video-bg.contain .video-fg {
      height: 100%;
      padding-bottom: 0;
      max-width: 53.33333333vh;
      left: 50%;
      margin-left: -26.66666667vh;
    }
  }
}

.admin-video-bg {
  iframe {
    width: 100%;
    height: 100%;
  }
}
.btn.btn-circle.btn-play {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: var(--secondary-text-color);
  &.contained-btn {
    position: relative;
    width: auto;
    height: auto;
  }
  span {
    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    font-size: 20px;
    border-radius: 100% !important;
    background: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    color: var(--black-text-color);
  }
}
.btn-circle.ripple span::before,
.btn-circle.ripple span::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  background: #fff;
  opacity: 0.8;
  animation: ripple-1 2s infinite ease-in-out;
  z-index: -1;
}
.plyr__control--overlaid span::after,
.btn-circle.ripple span::after {
  opacity: 0.6;
  animation: ripple-2 2s infinite ease-in-out;
  animation-delay: 0.5s;
}
.plyr--video.plyr--menu-open {
  overflow: hidden;
}
//----------------------------------------------------------//
//	RIPPLE ANIMATION
//----------------------------------------------------------//
@keyframes ripple-1 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes ripple-2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}
.gprev,
.gnext {
  display: none !important;
}

.job-list {
  .card {
    --bs-card-border-radius: 0;
    box-shadow: 0 0 0 0.05rem rgba(8, 60, 130, 0.06),
      0 0 1.25rem rgba(30, 34, 40, 0.04);
    border: 0;
  }
  a.lift {
    &:hover {
      .btn_1 {
        opacity: 0.6;
      }
    }
  }
  @media (max-width: 768px) {
    .line-infos {
      flex-wrap: wrap;
    }
    .data-name {
      width: 100%;
      margin-bottom: 15px;
    }
    .data-place,
    .data-titme {
      margin-left: 0 !important;
      width: 100%;
    }
    .btn_1 {
      margin-left: 0 !important;
      margin-top: 15px;
    }
  }
}

.job-infos-datas {
  .metas {
    font-size: 16px;
    font-weight: 600;
    i {
      width: 23px;
      text-align: center;
    }
  }
}

.cform_upl_preview {
  font-size: 15px;
  font-weight: 600;
  a {
    margin-right: 8px;
    i {
      font-size: 20px;
    }
  }
}
.cform_upload_inner {
  .cform_upl_preview_input {
    position: absolute;
    left: -9999px;
    height: 0;
  }
  .panel-upload {
    display: block;
    width: 100%;
    position: relative;
  }

  .cform_uplbtn-area {
    z-index: 20;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .panel-upload-dropzone {
    display: block;
    width: 100%;
    height: auto;
    text-align: center;
  }

  .panel-upload-dropzone {
    border: 2px dashed rgba(var(--bs-dark-rgb), 0.2);
    border-radius: 0;
    padding: 30px;
    &.invalid-field {
      border: 2px dashed #cd3646;
    }
  }

  .panel-upload-help-message {
    line-height: 13px;
    font-size: 11px;
    color: #777;
    padding-bottom: 5px;
  }

  .panel-upload-message {
    line-height: 13px;
    font-size: 11px;
    color: #777;
    padding-top: 5px;
  }

  .panel-upload-message.error {
    color: #cd3646;
  }

  .btn-uploader.disabled {
    display: none;
  }

  .cform_upl-progress {
    border-radius: 6px;
    background: rgba(#cacaca, 0.6);
    overflow: hidden;
    width: 100%;
    height: 15px;
    .cform_upl-progressbar {
      background: var(--secondary-text-color);
      width: 100%;
      height: 15px;
    }
  }
}
