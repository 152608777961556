/*
[Table of contents] 

- 1.1 Icons
- 1.3 Animations
- 1.4 Date Range Picker
- 1.5 Nice Select
*/  
  /*-------- Hamburgers --------*/
  .hamburger {
	padding: 0;
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	border: 0;
	margin: 0;
	overflow: visible;
  }
  
  .hamburger:hover {
	opacity: 0.7;
  }
  
  .hamburger-box {
	width: 24px;
	height: 24px;
	display: inline-block;
	position: relative;
  }
  
  .hamburger-inner {
	display: block;
	top: 50%;
	margin-top: -2px;
  }
  
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
	width: 24px;
	height: 2px;
	background-color: #fff;
	border-radius: 0;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;
  }
  
  .hamburger-inner::before,
  .hamburger-inner::after {
	content: "";
	display: block;
  }
  
  .hamburger-inner::before {
	top: -8px;
  }
  
  .hamburger-inner::after {
	bottom: -8px;
  }
  
  /*
	 * Spin
	 */
  .hamburger--spin .hamburger-inner {
	transition-duration: 0.22s;
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .hamburger--spin .hamburger-inner::before {
	transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
  }
  
  .hamburger--spin .hamburger-inner::after {
	transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .hamburger--spin.is-active .hamburger-inner {
	transform: rotate(225deg);
	transition-delay: 0.12s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .hamburger--spin.is-active .hamburger-inner::before {
	top: 0;
	opacity: 0;
	transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
  }
  
  .hamburger--spin.is-active .hamburger-inner::after {
	bottom: 0;
	transform: rotate(-90deg);
	transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  /*============================================================================================*/
  /* 1.3 Animations */
  /*============================================================================================*/
  /**  ---------------
	   fadeIn
  */
  [data-cue="fadeIn"], [data-cues="fadeIn"] > * {
	opacity: 0;
  }
  
  @keyframes fadeIn {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
  }
  /**  ---------------
	   slideInLeft
  */
  [data-cue="slideInLeft"], [data-cues="slideInLeft"] > * {
	opacity: 0;
  }
  
  @keyframes slideInLeft {
	from {
	  opacity: 0;
	  transform: translateX(-30px);
	}
	to {
	  opacity: 1;
	  transform: translateX(0);
	}
  }
  /**  ---------------
	   slideInRight
  */
  [data-cue="slideInRight"], [data-cues="slideInRight"] > * {
	opacity: 0;
  }
  
  @keyframes slideInRight {
	from {
	  opacity: 0;
	  transform: translateX(30px);
	}
	to {
	  opacity: 1;
	  transform: translateX(0);
	}
  }
  /**  ---------------
	   slideInDown
  */
  [data-cue="slideInDown"], [data-cues="slideInDown"] > * {
	opacity: 0;
  }
  
  @keyframes slideInDown {
	from {
	  opacity: 0;
	  transform: translateY(-30px);
	}
	to {
	  opacity: 1;
	  transform: translateY(0);
	}
  }
  /**  ---------------
	   slideInUp
  */
  [data-cue="slideInUp"], [data-cues="slideInUp"] > * {
	opacity: 0;
  }
  
  @keyframes slideInUp {
	from {
	  opacity: 0;
	  transform: translateY(30px);
	}
	to {
	  opacity: 1;
	  transform: translateY(0);
	}
  }
  /**  ---------------
	   zoomIn
  */
  [data-cue="zoomIn"], [data-cues="zoomIn"] > * {
	opacity: 0;
  }
  
  @keyframes zoomIn {
	from {
	  opacity: 0;
	  transform: scale(0.8);
	}
	to {
	  opacity: 1;
	  transform: scale(1);
	}
  }
  /**  ---------------
	   zoomOut
  */
  [data-cue="zoomOut"], [data-cues="zoomOut"] > * {
	opacity: 0;
  }
  
  @keyframes zoomOut {
	from {
	  opacity: 0;
	  transform: scale(1.2);
	}
	to {
	  opacity: 1;
	  transform: scale(1);
	}
  }
  /**  ---------------
	   rotateIn
  */
  [data-cue="rotateIn"], [data-cues="rotateIn"] > * {
	opacity: 0;
  }
  
  @keyframes rotateIn {
	from {
	  opacity: 0;
	  transform: rotateZ(-15deg);
	}
	to {
	  opacity: 1;
	  transform: rotateZ(0deg);
	}
  }
  /**  ---------------
	   bounceIn
  */
  [data-cue="bounceIn"], [data-cues="bounceIn"] > * {
	opacity: 0;
  }
  
  @keyframes bounceIn {
	0% {
	  transform: scale3d(0.3, 0.3, 0.3);
	}
	20% {
	  transform: scale3d(1.1, 1.1, 1.1);
	}
	40% {
	  transform: scale3d(0.9, 0.9, 0.9);
	}
	60% {
	  opacity: 1;
	  transform: scale3d(1.03, 1.03, 1.03);
	}
	80% {
	  transform: scale3d(0.97, 0.97, 0.97);
	}
	100% {
	  opacity: 1;
	  transform: scale3d(1, 1, 1);
	}
  }
  /**  ---------------
	   bounceInLeft
  */
  [data-cue="bounceInLeft"], [data-cues="bounceInLeft"] > * {
	opacity: 0;
  }
  
  @keyframes bounceInLeft {
	from,
	60%,
	75%,
	90%,
	to {
	  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	0% {
	  opacity: 0;
	  transform: translate3d(-3000px, 0, 0);
	}
	60% {
	  opacity: 1;
	  transform: translate3d(25px, 0, 0);
	}
	75% {
	  transform: translate3d(-10px, 0, 0);
	}
	90% {
	  transform: translate3d(5px, 0, 0);
	}
	to {
	  opacity: 1;
	  transform: translate3d(0, 0, 0);
	}
  }
  /**  ---------------
	   bounceInRight
  */
  [data-cue="bounceInRight"], [data-cues="bounceInRight"] > * {
	opacity: 0;
  }
  
  @keyframes bounceInRight {
	from,
	60%,
	75%,
	90%,
	to {
	  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	from {
	  opacity: 0;
	  transform: translate3d(3000px, 0, 0);
	}
	60% {
	  opacity: 1;
	  transform: translate3d(-25px, 0, 0);
	}
	75% {
	  transform: translate3d(10px, 0, 0);
	}
	90% {
	  transform: translate3d(-5px, 0, 0);
	}
	to {
	  opacity: 1;
	  transform: translate3d(0, 0, 0);
	}
  }
  /**  ---------------
	   bounceInDown
  */
  [data-cue="bounceInDown"], [data-cues="bounceInDown"] > * {
	opacity: 0;
  }
  
  @keyframes bounceInDown {
	from,
	60%,
	75%,
	90%,
	to {
	  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	0% {
	  opacity: 0;
	  transform: translate3d(0, -3000px, 0);
	}
	60% {
	  opacity: 1;
	  transform: translate3d(0, 25px, 0);
	}
	75% {
	  transform: translate3d(0, -10px, 0);
	}
	90% {
	  transform: translate3d(0, 5px, 0);
	}
	to {
	  opacity: 1;
	  transform: translate3d(0, 0, 0);
	}
  }
  /**  ---------------
	   bounceInUp
  */
  [data-cue="bounceInUp"], [data-cues="bounceInUp"] > * {
	opacity: 0;
  }
  
  @keyframes bounceInUp {
	from,
	60%,
	75%,
	90%,
	to {
	  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	from {
	  opacity: 0;
	  transform: translate3d(0, 3000px, 0);
	}
	60% {
	  opacity: 1;
	  transform: translate3d(0, -20px, 0);
	}
	75% {
	  transform: translate3d(0, 10px, 0);
	}
	90% {
	  transform: translate3d(0, -5px, 0);
	}
	to {
	  opacity: 1;
	  transform: translate3d(0, 0, 0);
	}
  }
  /**  ---------------
	   flipInX
  */
  [data-cue="flipInX"], [data-cues="flipInX"] > * {
	opacity: 0;
	backface-visibility: visible;
  }
  
  @keyframes flipInX {
	from {
	  transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	  animation-timing-function: ease-in;
	  opacity: 0;
	}
	40% {
	  transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	  animation-timing-function: ease-in;
	}
	60% {
	  transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	  opacity: 1;
	}
	80% {
	  transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	}
	to {
	  opacity: 1;
	  transform: perspective(400px);
	}
  }
  /**  ---------------
	   flipInY
  */
  [data-cue="flipInY"], [data-cues="flipInY"] > * {
	opacity: 0;
	backface-visibility: visible;
  }
  
  @keyframes flipInY {
	from {
	  transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
	  animation-timing-function: ease-in;
	  opacity: 0;
	}
	40% {
	  transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
	  animation-timing-function: ease-in;
	}
	60% {
	  transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
	  opacity: 1;
	}
	80% {
	  transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
	}
	to {
	  opacity: 1;
	  transform: perspective(400px);
	}
  }
  /*============================================================================================*/
  /* 1.4 Date Range Picker */
  /*============================================================================================*/
  .daterangepicker {
	position: absolute;
	color: inherit;
	background-color: #fff;
	border-radius: 4px;
	border: 1px solid #ddd;
	width: 278px;
	max-width: none;
	padding: 0;
	margin-top: 7px;
	top: 100px;
	left: 20px;
	z-index: 3001;
	display: none;
	font-family: arial;
	font-size: 15px;
	line-height: 1em;
  }
  
  .daterangepicker:before, .daterangepicker:after {
	position: absolute;
	display: inline-block;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	content: '';
  }
  
  .daterangepicker:before {
	top: -7px;
	border-right: 7px solid transparent;
	border-left: 7px solid transparent;
	border-bottom: 7px solid #ccc;
  }
  
  .daterangepicker:after {
	top: -6px;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	border-left: 6px solid transparent;
  }
  
  .daterangepicker.opensleft:before {
	right: 9px;
  }
  
  .daterangepicker.opensleft:after {
	right: 10px;
  }
  
  .daterangepicker.openscenter:before {
	left: 0;
	right: 0;
	width: 0;
	margin-left: auto;
	margin-right: auto;
  }
  
  .daterangepicker.openscenter:after {
	left: 0;
	right: 0;
	width: 0;
	margin-left: auto;
	margin-right: auto;
  }
  
  .daterangepicker.opensright:before {
	left: 9px;
  }
  
  .daterangepicker.opensright:after {
	left: 10px;
  }
  
  .daterangepicker.drop-up {
	margin-top: -7px;
  }
  
  .daterangepicker.drop-up:before {
	top: initial;
	bottom: -7px;
	border-bottom: initial;
	border-top: 7px solid #ccc;
  }
  
  .daterangepicker.drop-up:after {
	top: initial;
	bottom: -6px;
	border-bottom: initial;
	border-top: 6px solid #fff;
  }
  
  .daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
	float: none;
  }
  
  .daterangepicker.single .drp-selected {
	display: none;
  }
  
  .daterangepicker.show-calendar .drp-calendar {
	display: block;
  }
  
  .daterangepicker.show-calendar .drp-buttons {
	display: block;
  }
  
  .daterangepicker.auto-apply .drp-buttons {
	display: none;
  }
  
  .daterangepicker .drp-calendar {
	display: none;
	max-width: 270px;
  }
  
  .daterangepicker .drp-calendar.left {
	padding: 8px 0 8px 8px;
  }
  
  .daterangepicker .drp-calendar.right {
	padding: 8px;
  }
  
  .daterangepicker .drp-calendar.single .calendar-table {
	border: none;
  }
  
  .daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
	color: #fff;
	border: solid black;
	border-width: 0 2px 2px 0;
	border-radius: 0;
	display: inline-block;
	padding: 3px;
  }
  
  .daterangepicker .calendar-table .next span {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
  }
  
  .daterangepicker .calendar-table .prev span {
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
  }
  
  .daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
	white-space: nowrap;
	text-align: center;
	vertical-align: middle;
	min-width: 32px;
	width: 32px;
	height: 24px;
	line-height: 24px;
	font-size: 12px;
	border-radius: 4px;
	border: 1px solid transparent;
	white-space: nowrap;
	cursor: pointer;
  }
  
  .daterangepicker .calendar-table {
	border: 1px solid #fff;
	border-radius: 4px;
	background-color: #fff;
  }
  
  .daterangepicker .calendar-table table {
	width: 100%;
	margin: 0;
	border-spacing: 0;
	border-collapse: collapse;
  }
  
  .daterangepicker td.available:hover, .daterangepicker th.available:hover {
	background-color: #eee;
	border-color: transparent;
	color: inherit;
  }
  
  .daterangepicker td.week, .daterangepicker th.week {
	font-size: 80%;
	color: #ccc;
  }
  
  .daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
	background-color: #fff;
	border-color: transparent;
	color: #999;
  }
  
  .daterangepicker td.in-range {
	background-color: #ebf4f8;
	border-color: transparent;
	color: #000;
	border-radius: 0;
  }
  
  .daterangepicker td.start-date {
	border-radius: 4px 0 0 4px;
  }
  
  .daterangepicker td.end-date {
	border-radius: 0 4px 4px 0;
  }
  
  .daterangepicker td.start-date.end-date {
	border-radius: 4px;
  }
  
  .daterangepicker td.active, .daterangepicker td.active:hover {
	background-color: #978667;
	border-color: transparent;
	color: #fff;
  }
  
  .daterangepicker th.month {
	width: auto;
  }
  
  .daterangepicker td.disabled, .daterangepicker option.disabled {
	color: #999;
	cursor: not-allowed;
	text-decoration: line-through;
  }
  
  .daterangepicker select.monthselect, .daterangepicker select.yearselect {
	font-size: 12px;
	padding: 1px;
	height: auto;
	margin: 0;
	cursor: default;
  }
  
  .daterangepicker select.monthselect {
	margin-right: 2%;
	width: 56%;
  }
  
  .daterangepicker select.yearselect {
	width: 40%;
  }
  
  .daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
	width: 50px;
	margin: 0 auto;
	background: #eee;
	border: 1px solid #eee;
	padding: 2px;
	outline: 0;
	font-size: 12px;
  }
  
  .daterangepicker .calendar-time {
	text-align: center;
	margin: 4px auto 0 auto;
	line-height: 30px;
	position: relative;
  }
  
  .daterangepicker .calendar-time select.disabled {
	color: #ccc;
	cursor: not-allowed;
  }
  
  .daterangepicker .drp-buttons {
	clear: both;
	text-align: right;
	padding: 8px;
	border-top: 1px solid #ddd;
	display: none;
	line-height: 12px;
	vertical-align: middle;
  }
  
  .daterangepicker .drp-selected {
	display: inline-block;
	font-size: 12px;
	padding-right: 8px;
  }
  
  .daterangepicker .drp-buttons .btn {
	margin-left: 8px;
	font-size: 12px;
	font-weight: bold;
	padding: 4px 8px;
  }
  
  .daterangepicker.show-ranges.single.rtl .drp-calendar.left {
	border-right: 1px solid #ddd;
  }
  
  .daterangepicker.show-ranges.single.ltr .drp-calendar.left {
	border-left: 1px solid #ddd;
  }
  
  .daterangepicker.show-ranges.rtl .drp-calendar.right {
	border-right: 1px solid #ddd;
  }
  
  .daterangepicker.show-ranges.ltr .drp-calendar.left {
	border-left: 1px solid #ddd;
  }
  
  .daterangepicker .ranges {
	float: none;
	text-align: left;
	margin: 0;
  }
  
  .daterangepicker.show-calendar .ranges {
	margin-top: 8px;
  }
  
  .daterangepicker .ranges ul {
	list-style: none;
	margin: 0 auto;
	padding: 0;
	width: 100%;
  }
  
  .daterangepicker .ranges li {
	font-size: 12px;
	padding: 8px 12px;
	cursor: pointer;
  }
  
  .daterangepicker .ranges li:hover {
	background-color: #eee;
  }
  
  .daterangepicker .ranges li.active {
	background-color: #08c;
	color: #fff;
  }
  
  /*  Larger Screen Styling */
  @media (min-width: 564px) {
	.daterangepicker {
	  width: auto;
	}
  
	.daterangepicker .ranges ul {
	  width: 140px;
	}
  
	.daterangepicker.single .ranges ul {
	  width: 100%;
	}
  
	.daterangepicker.single .drp-calendar.left {
	  clear: none;
	}
  
	.daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {
	  float: left;
	}
  
	.daterangepicker {
	  direction: ltr;
	  text-align: left;
	}
  
	.daterangepicker .drp-calendar.left {
	  clear: left;
	  margin-right: 0;
	}
  
	.daterangepicker .drp-calendar.left .calendar-table {
	  border-right: none;
	  border-top-right-radius: 0;
	  border-bottom-right-radius: 0;
	}
  
	.daterangepicker .drp-calendar.right {
	  margin-left: 0;
	}
  
	.daterangepicker .drp-calendar.right .calendar-table {
	  border-left: none;
	  border-top-left-radius: 0;
	  border-bottom-left-radius: 0;
	}
  
	.daterangepicker .drp-calendar.left .calendar-table {
	  padding-right: 8px;
	}
  
	.daterangepicker .ranges, .daterangepicker .drp-calendar {
	  float: left;
	}
  }
  @media (min-width: 730px) {
	.daterangepicker .ranges {
	  width: auto;
	}
  
	.daterangepicker .ranges {
	  float: left;
	}
  
	.daterangepicker.rtl .ranges {
	  float: right;
	}
  
	.daterangepicker .drp-calendar.left {
	  clear: none !important;
	}
  }
  /* Embedded/inline calendar */
  .embedded-daterangepicker .daterangepicker::before,
  .embedded-daterangepicker .daterangepicker::after {
	display: none;
  }
  
  .embedded-daterangepicker .daterangepicker {
	position: relative !important;
	top: auto !important;
	left: auto !important;
	float: left;
	width: 100%;
	margin-top: 0;
	box-shadow: none;
	border: 1px solid #ededed !important;
  }
  
  @media (min-width: 730px) {
	.embedded-daterangepicker .daterangepicker .drp-calendar {
	  width: 50%;
	  max-width: 50%;
	}
  
	.embedded-daterangepicker .daterangepicker .drp-calendar.left {
	  border-right: 1px solid #ededed;
	}
  
	.embedded-daterangepicker .calendar-table td {
	  max-width: 46px;
	  line-height: 36px;
	}
  }
  @media (max-width: 729px) {
	.embedded-daterangepicker .daterangepicker .drp-calendar {
	  width: 100%;
	  max-width: inherit;
	}
  
	.embedded-daterangepicker .daterangepicker .drp-calendar.left {
	  border-bottom: 1px solid #ededed;
	}
  }
  /*============================================================================================*/
  /* 1.5 Nice Select  */
  /*============================================================================================*/
  .nice-select {
	-webkit-tap-highlight-color: transparent;
	background-color: #fff;
	border-radius: 3px;
	border: 1px solid #ededed;
	box-sizing: border-box;
	clear: both;
	cursor: pointer;
	display: block;
	float: left;
	font-family: inherit;
	font-size: 14px;
	font-weight: normal;
	height: 50px;
	line-height: 48px;
	outline: none;
	padding-left: 15px;
	padding-right: 27px;
	position: relative;
	text-align: left !important;
	transition: all 0.2s ease-in-out;
	user-select: none;
	white-space: nowrap;
	width: auto;
	color: #333;
	margin-bottom: 20px;
  }
  .nice-select:hover {
	border-color: #ededed;
  }
  .nice-select:active, .nice-select.open, .nice-select:focus {
	border-color: border-color;
  }
  .nice-select:after {
	border-bottom: 2px solid #978667;
	border-right: 2px solid #978667;
	content: '';
	display: block;
	height: 8px;
	margin-top: -5px;
	pointer-events: none;
	position: absolute;
	right: 20px;
	top: 50%;
	transform-origin: 66% 66%;
	transform: rotate(45deg);
	transition: all 0.15s ease-in-out;
	width: 8px;
  }
  .nice-select.open:after {
	transform: rotate(-135deg);
  }
  .nice-select.open .list {
	opacity: 1;
	pointer-events: auto;
	transform: scale(1) translateY(0);
  }
  .nice-select.disabled {
	border-color: #9e9e9e;
	color: #999;
	pointer-events: none;
  }
  .nice-select.disabled:after {
	border-color: #c1b6a3;
  }
  .nice-select.wide {
	width: 100%;
  }
  .nice-select.wide .list {
	left: 0 !important;
	right: 0 !important;
  }
  .nice-select.right {
	float: right;
  }
  .nice-select.right .list {
	left: auto;
	right: 0;
  }
  .nice-select.small {
	font-size: 12px;
	height: 36px;
	line-height: 34px;
  }
  .nice-select.small:after {
	height: 4px;
	width: 4px;
  }
  .nice-select.small .option {
	line-height: 34px;
	min-height: 34px;
  }
  .nice-select .list {
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
	box-sizing: border-box;
	margin-top: 4px;
	opacity: 0;
	overflow: hidden;
	padding: 0;
	pointer-events: none;
	position: absolute;
	top: 100%;
	left: 0;
	transform-origin: 50% 0;
	transform: scale(0.75) translateY(-25px);
	transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
	z-index: 9;
	height: auto;
	overflow: auto;
  }
  .nice-select .list:hover .option:not(:hover) {
	background-color: transparent !important;
  }
  .nice-select .list::-webkit-scrollbar {
	width: 14px;
	height: 18px;
  }
  .nice-select .list::-webkit-scrollbar-thumb {
	height: 6px;
	border: 4px solid transparent;
	background-clip: padding-box;
	-webkit-border-radius: 7px;
	background-color: rgba(0, 0, 0, 0.15);
	-webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  .nice-select .list::-webkit-scrollbar-button {
	width: 0;
	height: 0;
	display: none;
  }
  .nice-select .list::-webkit-scrollbar-corner {
	background-color: transparent;
  }
  .nice-select .option {
	cursor: pointer;
	font-weight: 400;
	line-height: 38px;
	list-style: none;
	min-height: 38px;
	outline: none;
	padding-left: 15px;
	padding-right: 26px;
	text-align: left;
	transition: all 0.2s;
  }
  .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
	background-color: #f6f6f6;
  }
  .nice-select .option.selected {
	font-weight: 500;
  }
  .nice-select .option.disabled {
	background-color: transparent;
	color: #999;
	cursor: default;
  }
  
  .no-csspointerevents .nice-select .list {
	display: none;
  }
  .no-csspointerevents .nice-select.open .list {
	display: block;
  }
  