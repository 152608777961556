@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.min.css";
@import "../../node_modules/hotel-datepicker/dist/css/hotel-datepicker.css";
@import "../../node_modules/glightbox/dist/css/glightbox.min.css";

@import "library/_owl";

@import '../fonts/icons/style.css';

@import '_vendors';
@import '_theme';