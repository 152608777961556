@font-face {
  font-family: 'icomoon';
  src:  url('./icomoon.eot?bdq3g4');
  src:  url('./icomoon.eot?bdq3g4#iefix') format('embedded-opentype'),
    url('./icomoon.ttf?bdq3g4') format('truetype'),
    url('./icomoon.woff?bdq3g4') format('woff'),
    url('./icomoon.svg?bdq3g4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="amicon-"], [class*=" amicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a[class^="amicon-"], a[class*=" amicon-"] {
	font-family: inherit !important;
}
a[class^="amicon-"]:before , a[class*=" amicon-"]:before {
		font-family: 'icomoon' !important;
		margin-right: 15px;
}

.amicon-icon-pmr:before {
  content: "\e923";
}
.amicon-icon-desk:before {
  content: "\e924";
}
.amicon-icon-noclim:before {
  content: "\e91c";
}
.amicon-icon-fan:before {
  content: "\e918";
}
.amicon-icon-mosquito-net:before {
  content: "\e919";
}
.amicon-icon-hair-dryer:before {
  content: "\e91a";
}
.amicon-icon-view:before {
  content: "\e91b";
}
.amicon-icon-dishes:before {
  content: "\e91d";
}
.amicon-icon-kitchen:before {
  content: "\e91e";
}
.amicon-icon-washing:before {
  content: "\e91f";
}
.amicon-icon-tv:before {
  content: "\e920";
}
.amicon-icon-fridge:before {
  content: "\e921";
}
.amicon-icon-clim:before {
  content: "\e922";
}
.amicon-icon-parking:before {
  content: "\e900";
}
.amicon-icon-toilets:before {
  content: "\e901";
}
.amicon-icon-wifi:before {
  content: "\e902";
}
.amicon-icon-towel:before {
  content: "\e903";
}
.amicon-icon-shower:before {
  content: "\e904";
}
.amicon-icon-bath:before {
  content: "\e905";
}
.amicon-icon-calendar:before {
  content: "\e906";
}
.amicon-icon-fish:before {
  content: "\e907";
}
.amicon-icon-newspaper:before {
  content: "\e908";
}
.amicon-icon-people:before {
  content: "\e909";
}
.amicon-icon-locker:before {
  content: "\e90a";
}
.amicon-icon-cocktail:before {
  content: "\e90b";
}
.amicon-icon-door-hanger:before {
  content: "\e90c";
}
.amicon-icon-pool:before {
  content: "\e90d";
}
.amicon-icon-restaurant:before {
  content: "\e90e";
}
.amicon-icon-drink:before {
  content: "\e90f";
}
.amicon-icon-luggage:before {
  content: "\e910";
}
.amicon-icon-apero:before {
  content: "\e911";
}
.amicon-icon-surface:before {
  content: "\e912";
}
.amicon-icon-bed:before {
  content: "\e913";
}
.amicon-icon-coffee:before {
  content: "\e914";
}
.amicon-icon-taxi:before {
  content: "\e915";
}
.amicon-icon-bus:before {
  content: "\e916";
}
.amicon-icon-plane:before {
  content: "\e917";
}
.amicon-uni41:before {
  content: "\41";
}
.amicon-uni42:before {
  content: "\42";
}
.amicon-uni43:before {
  content: "\43";
}
.amicon-uni44:before {
  content: "\44";
}
.amicon-uni45:before {
  content: "\45";
}
.amicon-uni46:before {
  content: "\46";
}
.amicon-uni47:before {
  content: "\47";
}
.amicon-uni48:before {
  content: "\48";
}
.amicon-uni49:before {
  content: "\49";
}
.amicon-uni4A:before {
  content: "\4a";
}
.amicon-uni4B:before {
  content: "\4b";
}
.amicon-uni4C:before {
  content: "\4c";
}
.amicon-uni4D:before {
  content: "\4d";
}
.amicon-uni4E:before {
  content: "\4e";
}
.amicon-uni4F:before {
  content: "\4f";
}
.amicon-uni50:before {
  content: "\50";
}
.amicon-uni51:before {
  content: "\51";
}
.amicon-uni52:before {
  content: "\52";
}
.amicon-uni53:before {
  content: "\53";
}
.amicon-uni54:before {
  content: "\54";
}
.amicon-uni55:before {
  content: "\55";
}
.amicon-uni56:before {
  content: "\56";
}
.amicon-uni57:before {
  content: "\57";
}
.amicon-uni58:before {
  content: "\58";
}
.amicon-uni59:before {
  content: "\59";
}
.amicon-uni5A:before {
  content: "\5a";
}
.amicon-uni61:before {
  content: "\61";
}
.amicon-uni62:before {
  content: "\62";
}
.amicon-uni63:before {
  content: "\63";
}
.amicon-uni64:before {
  content: "\64";
}
.amicon-uni65:before {
  content: "\65";
}
.amicon-uni66:before {
  content: "\66";
}
.amicon-uni67:before {
  content: "\67";
}
.amicon-uni68:before {
  content: "\68";
}
.amicon-uni69:before {
  content: "\69";
}
.amicon-uni6A:before {
  content: "\6a";
}
.amicon-uni6B:before {
  content: "\6b";
}
.amicon-uni5B:before {
  content: "\5b";
}
.amicon-uni5C:before {
  content: "\5c";
}
.amicon-uni5D:before {
  content: "\5d";
}
.amicon-uni5E:before {
  content: "\5e";
}
.amicon-uni5F:before {
  content: "\5f";
}
.amicon-uni60:before {
  content: "\60";
}
.amicon-uni6C:before {
  content: "\6c";
}
.amicon-uni6D:before {
  content: "\6d";
}
.amicon-uni6E:before {
  content: "\6e";
}
.amicon-uni6F:before {
  content: "\6f";
}
.amicon-uni70:before {
  content: "\70";
}
.amicon-uni71:before {
  content: "\71";
}
.amicon-uni72:before {
  content: "\72";
}
.amicon-uni73:before {
  content: "\73";
}
.amicon-uni74:before {
  content: "\74";
}
.amicon-uni75:before {
  content: "\75";
}
.amicon-uni76:before {
  content: "\76";
}
.amicon-uni77:before {
  content: "\77";
}
.amicon-uni78:before {
  content: "\78";
}
.amicon-uni79:before {
  content: "\79";
}
.amicon-uni7A:before {
  content: "\7a";
}
.amicon-uni7B:before {
  content: "\7b";
}
.amicon-uni7C:before {
  content: "\7c";
}
.amicon-uni7D:before {
  content: "\7d";
}
.amicon-uni7E:before {
  content: "\7e";
}
.amicon-uni7F:before {
  content: "\7f";
}
.amicon-uni80:before {
  content: "\80";
}
.amicon-uni81:before {
  content: "\81";
}
.amicon-uni82:before {
  content: "\82";
}
.amicon-uni83:before {
  content: "\83";
}
.amicon-uni84:before {
  content: "\84";
}
.amicon-uni85:before {
  content: "\85";
}
.amicon-uni86:before {
  content: "\86";
}
.amicon-uni87:before {
  content: "\87";
}
.amicon-uni88:before {
  content: "\88";
}
.amicon-uni89:before {
  content: "\89";
}
.amicon-uni8A:before {
  content: "\8a";
}
.amicon-uni8B:before {
  content: "\8b";
}
.amicon-uni8C:before {
  content: "\8c";
}
.amicon-asterisk:before {
  content: "\f069";
}
.amicon-plus:before {
  content: "\f067";
}
.amicon-question:before {
  content: "\f128";
}
.amicon-minus:before {
  content: "\f068";
}
.amicon-glass:before {
  content: "\f000";
}
.amicon-music:before {
  content: "\f001";
}
.amicon-search:before {
  content: "\f002";
}
.amicon-envelope-o:before {
  content: "\f003";
}
.amicon-heart:before {
  content: "\f004";
}
.amicon-star:before {
  content: "\f005";
}
.amicon-star-o:before {
  content: "\f006";
}
.amicon-user:before {
  content: "\f007";
}
.amicon-film:before {
  content: "\f008";
}
.amicon-th-large:before {
  content: "\f009";
}
.amicon-th:before {
  content: "\f00a";
}
.amicon-th-list:before {
  content: "\f00b";
}
.amicon-check:before {
  content: "\f00c";
}
.amicon-close:before {
  content: "\f00d";
}
.amicon-remove:before {
  content: "\f00d";
}
.amicon-times:before {
  content: "\f00d";
}
.amicon-search-plus:before {
  content: "\f00e";
}
.amicon-search-minus:before {
  content: "\f010";
}
.amicon-power-off:before {
  content: "\f011";
}
.amicon-signal:before {
  content: "\f012";
}
.amicon-cog:before {
  content: "\f013";
}
.amicon-gear:before {
  content: "\f013";
}
.amicon-trash-o:before {
  content: "\f014";
}
.amicon-home:before {
  content: "\f015";
}
.amicon-file-o:before {
  content: "\f016";
}
.amicon-clock-o:before {
  content: "\f017";
}
.amicon-road:before {
  content: "\f018";
}
.amicon-download:before {
  content: "\f019";
}
.amicon-arrow-circle-o-down:before {
  content: "\f01a";
}
.amicon-arrow-circle-o-up:before {
  content: "\f01b";
}
.amicon-inbox:before {
  content: "\f01c";
}
.amicon-play-circle-o:before {
  content: "\f01d";
}
.amicon-repeat:before {
  content: "\f01e";
}
.amicon-rotate-right:before {
  content: "\f01e";
}
.amicon-refresh:before {
  content: "\f021";
}
.amicon-list-alt:before {
  content: "\f022";
}
.amicon-lock:before {
  content: "\f023";
}
.amicon-flag:before {
  content: "\f024";
}
.amicon-headphones:before {
  content: "\f025";
}
.amicon-volume-off:before {
  content: "\f026";
}
.amicon-volume-down:before {
  content: "\f027";
}
.amicon-volume-up:before {
  content: "\f028";
}
.amicon-qrcode:before {
  content: "\f029";
}
.amicon-barcode:before {
  content: "\f02a";
}
.amicon-tag:before {
  content: "\f02b";
}
.amicon-tags:before {
  content: "\f02c";
}
.amicon-book:before {
  content: "\f02d";
}
.amicon-bookmark:before {
  content: "\f02e";
}
.amicon-print:before {
  content: "\f02f";
}
.amicon-camera:before {
  content: "\f030";
}
.amicon-font:before {
  content: "\f031";
}
.amicon-bold:before {
  content: "\f032";
}
.amicon-italic:before {
  content: "\f033";
}
.amicon-text-height:before {
  content: "\f034";
}
.amicon-text-width:before {
  content: "\f035";
}
.amicon-align-left:before {
  content: "\f036";
}
.amicon-align-center:before {
  content: "\f037";
}
.amicon-align-right:before {
  content: "\f038";
}
.amicon-align-justify:before {
  content: "\f039";
}
.amicon-list:before {
  content: "\f03a";
}
.amicon-dedent:before {
  content: "\f03b";
}
.amicon-outdent:before {
  content: "\f03b";
}
.amicon-indent:before {
  content: "\f03c";
}
.amicon-video-camera:before {
  content: "\f03d";
}
.amicon-image:before {
  content: "\f03e";
}
.amicon-photo:before {
  content: "\f03e";
}
.amicon-picture-o:before {
  content: "\f03e";
}
.amicon-pencil:before {
  content: "\f040";
}
.amicon-map-marker:before {
  content: "\f041";
}
.amicon-adjust:before {
  content: "\f042";
}
.amicon-tint:before {
  content: "\f043";
}
.amicon-edit:before {
  content: "\f044";
}
.amicon-pencil-square-o:before {
  content: "\f044";
}
.amicon-share-square-o:before {
  content: "\f045";
}
.amicon-check-square-o:before {
  content: "\f046";
}
.amicon-arrows:before {
  content: "\f047";
}
.amicon-step-backward:before {
  content: "\f048";
}
.amicon-fast-backward:before {
  content: "\f049";
}
.amicon-backward:before {
  content: "\f04a";
}
.amicon-play:before {
  content: "\f04b";
}
.amicon-pause:before {
  content: "\f04c";
}
.amicon-stop:before {
  content: "\f04d";
}
.amicon-forward:before {
  content: "\f04e";
}
.amicon-fast-forward:before {
  content: "\f050";
}
.amicon-step-forward:before {
  content: "\f051";
}
.amicon-eject:before {
  content: "\f052";
}
.amicon-chevron-left:before {
  content: "\f053";
}
.amicon-chevron-right:before {
  content: "\f054";
}
.amicon-plus-circle:before {
  content: "\f055";
}
.amicon-minus-circle:before {
  content: "\f056";
}
.amicon-times-circle:before {
  content: "\f057";
}
.amicon-check-circle:before {
  content: "\f058";
}
.amicon-question-circle:before {
  content: "\f059";
}
.amicon-info-circle:before {
  content: "\f05a";
}
.amicon-crosshairs:before {
  content: "\f05b";
}
.amicon-times-circle-o:before {
  content: "\f05c";
}
.amicon-check-circle-o:before {
  content: "\f05d";
}
.amicon-ban:before {
  content: "\f05e";
}
.amicon-arrow-left:before {
  content: "\f060";
}
.amicon-arrow-right:before {
  content: "\f061";
}
.amicon-arrow-up:before {
  content: "\f062";
}
.amicon-arrow-down:before {
  content: "\f063";
}
.amicon-mail-forward:before {
  content: "\f064";
}
.amicon-share:before {
  content: "\f064";
}
.amicon-expand:before {
  content: "\f065";
}
.amicon-compress:before {
  content: "\f066";
}
.amicon-exclamation-circle:before {
  content: "\f06a";
}
.amicon-gift:before {
  content: "\f06b";
}
.amicon-leaf:before {
  content: "\f06c";
}
.amicon-fire:before {
  content: "\f06d";
}
.amicon-eye:before {
  content: "\f06e";
}
.amicon-eye-slash:before {
  content: "\f070";
}
.amicon-exclamation-triangle:before {
  content: "\f071";
}
.amicon-warning:before {
  content: "\f071";
}
.amicon-plane:before {
  content: "\f072";
}
.amicon-calendar:before {
  content: "\f073";
}
.amicon-random:before {
  content: "\f074";
}
.amicon-comment:before {
  content: "\f075";
}
.amicon-magnet:before {
  content: "\f076";
}
.amicon-chevron-up:before {
  content: "\f077";
}
.amicon-chevron-down:before {
  content: "\f078";
}
.amicon-retweet:before {
  content: "\f079";
}
.amicon-shopping-cart:before {
  content: "\f07a";
}
.amicon-folder:before {
  content: "\f07b";
}
.amicon-folder-open:before {
  content: "\f07c";
}
.amicon-arrows-v:before {
  content: "\f07d";
}
.amicon-arrows-h:before {
  content: "\f07e";
}
.amicon-bar-chart:before {
  content: "\f080";
}
.amicon-bar-chart-o:before {
  content: "\f080";
}
.amicon-twitter-square:before {
  content: "\f081";
}
.amicon-facebook-square:before {
  content: "\f082";
}
.amicon-camera-retro:before {
  content: "\f083";
}
.amicon-key:before {
  content: "\f084";
}
.amicon-cogs:before {
  content: "\f085";
}
.amicon-gears:before {
  content: "\f085";
}
.amicon-comments:before {
  content: "\f086";
}
.amicon-thumbs-o-up:before {
  content: "\f087";
}
.amicon-thumbs-o-down:before {
  content: "\f088";
}
.amicon-star-half:before {
  content: "\f089";
}
.amicon-heart-o:before {
  content: "\f08a";
}
.amicon-sign-out:before {
  content: "\f08b";
}
.amicon-linkedin-square:before {
  content: "\f08c";
}
.amicon-thumb-tack:before {
  content: "\f08d";
}
.amicon-external-link:before {
  content: "\f08e";
}
.amicon-sign-in:before {
  content: "\f090";
}
.amicon-trophy:before {
  content: "\f091";
}
.amicon-github-square:before {
  content: "\f092";
}
.amicon-upload:before {
  content: "\f093";
}
.amicon-lemon-o:before {
  content: "\f094";
}
.amicon-phone:before {
  content: "\f095";
}
.amicon-square-o:before {
  content: "\f096";
}
.amicon-bookmark-o:before {
  content: "\f097";
}
.amicon-phone-square:before {
  content: "\f098";
}
.amicon-twitter:before {
  content: "\f099";
}
.amicon-facebook:before {
  content: "\f09a";
}
.amicon-facebook-f:before {
  content: "\f09a";
}
.amicon-github:before {
  content: "\f09b";
}
.amicon-unlock:before {
  content: "\f09c";
}
.amicon-credit-card:before {
  content: "\f09d";
}
.amicon-feed:before {
  content: "\f09e";
}
.amicon-rss:before {
  content: "\f09e";
}
.amicon-hdd-o:before {
  content: "\f0a0";
}
.amicon-bullhorn:before {
  content: "\f0a1";
}
.amicon-bell-o:before {
  content: "\f0a2";
}
.amicon-certificate:before {
  content: "\f0a3";
}
.amicon-hand-o-right:before {
  content: "\f0a4";
}
.amicon-hand-o-left:before {
  content: "\f0a5";
}
.amicon-hand-o-up:before {
  content: "\f0a6";
}
.amicon-hand-o-down:before {
  content: "\f0a7";
}
.amicon-arrow-circle-left:before {
  content: "\f0a8";
}
.amicon-arrow-circle-right:before {
  content: "\f0a9";
}
.amicon-arrow-circle-up:before {
  content: "\f0aa";
}
.amicon-arrow-circle-down:before {
  content: "\f0ab";
}
.amicon-globe:before {
  content: "\f0ac";
}
.amicon-wrench:before {
  content: "\f0ad";
}
.amicon-tasks:before {
  content: "\f0ae";
}
.amicon-filter:before {
  content: "\f0b0";
}
.amicon-briefcase:before {
  content: "\f0b1";
}
.amicon-arrows-alt:before {
  content: "\f0b2";
}
.amicon-group:before {
  content: "\f0c0";
}
.amicon-users:before {
  content: "\f0c0";
}
.amicon-chain:before {
  content: "\f0c1";
}
.amicon-link:before {
  content: "\f0c1";
}
.amicon-cloud:before {
  content: "\f0c2";
}
.amicon-flask:before {
  content: "\f0c3";
}
.amicon-cut:before {
  content: "\f0c4";
}
.amicon-scissors:before {
  content: "\f0c4";
}
.amicon-copy:before {
  content: "\f0c5";
}
.amicon-files-o:before {
  content: "\f0c5";
}
.amicon-paperclip:before {
  content: "\f0c6";
}
.amicon-floppy-o:before {
  content: "\f0c7";
}
.amicon-save:before {
  content: "\f0c7";
}
.amicon-square:before {
  content: "\f0c8";
}
.amicon-bars:before {
  content: "\f0c9";
}
.amicon-navicon:before {
  content: "\f0c9";
}
.amicon-reorder:before {
  content: "\f0c9";
}
.amicon-list-ul:before {
  content: "\f0ca";
}
.amicon-list-ol:before {
  content: "\f0cb";
}
.amicon-strikethrough:before {
  content: "\f0cc";
}
.amicon-underline:before {
  content: "\f0cd";
}
.amicon-table:before {
  content: "\f0ce";
}
.amicon-magic:before {
  content: "\f0d0";
}
.amicon-truck:before {
  content: "\f0d1";
}
.amicon-pinterest:before {
  content: "\f0d2";
}
.amicon-pinterest-square:before {
  content: "\f0d3";
}
.amicon-google-plus-square:before {
  content: "\f0d4";
}
.amicon-google-plus:before {
  content: "\f0d5";
}
.amicon-money:before {
  content: "\f0d6";
}
.amicon-caret-down:before {
  content: "\f0d7";
}
.amicon-caret-up:before {
  content: "\f0d8";
}
.amicon-caret-left:before {
  content: "\f0d9";
}
.amicon-caret-right:before {
  content: "\f0da";
}
.amicon-columns:before {
  content: "\f0db";
}
.amicon-sort:before {
  content: "\f0dc";
}
.amicon-unsorted:before {
  content: "\f0dc";
}
.amicon-sort-desc:before {
  content: "\f0dd";
}
.amicon-sort-down:before {
  content: "\f0dd";
}
.amicon-sort-asc:before {
  content: "\f0de";
}
.amicon-sort-up:before {
  content: "\f0de";
}
.amicon-envelope:before {
  content: "\f0e0";
}
.amicon-linkedin:before {
  content: "\f0e1";
}
.amicon-rotate-left:before {
  content: "\f0e2";
}
.amicon-undo:before {
  content: "\f0e2";
}
.amicon-gavel:before {
  content: "\f0e3";
}
.amicon-legal:before {
  content: "\f0e3";
}
.amicon-dashboard:before {
  content: "\f0e4";
}
.amicon-tachometer:before {
  content: "\f0e4";
}
.amicon-comment-o:before {
  content: "\f0e5";
}
.amicon-comments-o:before {
  content: "\f0e6";
}
.amicon-bolt:before {
  content: "\f0e7";
}
.amicon-flash:before {
  content: "\f0e7";
}
.amicon-sitemap:before {
  content: "\f0e8";
}
.amicon-umbrella:before {
  content: "\f0e9";
}
.amicon-clipboard:before {
  content: "\f0ea";
}
.amicon-paste:before {
  content: "\f0ea";
}
.amicon-lightbulb-o:before {
  content: "\f0eb";
}
.amicon-exchange:before {
  content: "\f0ec";
}
.amicon-cloud-download:before {
  content: "\f0ed";
}
.amicon-cloud-upload:before {
  content: "\f0ee";
}
.amicon-user-md:before {
  content: "\f0f0";
}
.amicon-stethoscope:before {
  content: "\f0f1";
}
.amicon-suitcase:before {
  content: "\f0f2";
}
.amicon-bell:before {
  content: "\f0f3";
}
.amicon-coffee:before {
  content: "\f0f4";
}
.amicon-cutlery:before {
  content: "\f0f5";
}
.amicon-file-text-o:before {
  content: "\f0f6";
}
.amicon-building-o:before {
  content: "\f0f7";
}
.amicon-hospital-o:before {
  content: "\f0f8";
}
.amicon-ambulance:before {
  content: "\f0f9";
}
.amicon-medkit:before {
  content: "\f0fa";
}
.amicon-fighter-jet:before {
  content: "\f0fb";
}
.amicon-beer:before {
  content: "\f0fc";
}
.amicon-h-square:before {
  content: "\f0fd";
}
.amicon-plus-square:before {
  content: "\f0fe";
}
.amicon-angle-double-left:before {
  content: "\f100";
}
.amicon-angle-double-right:before {
  content: "\f101";
}
.amicon-angle-double-up:before {
  content: "\f102";
}
.amicon-angle-double-down:before {
  content: "\f103";
}
.amicon-angle-left:before {
  content: "\f104";
}
.amicon-angle-right:before {
  content: "\f105";
}
.amicon-angle-up:before {
  content: "\f106";
}
.amicon-angle-down:before {
  content: "\f107";
}
.amicon-desktop:before {
  content: "\f108";
}
.amicon-laptop:before {
  content: "\f109";
}
.amicon-tablet:before {
  content: "\f10a";
}
.amicon-mobile:before {
  content: "\f10b";
}
.amicon-mobile-phone:before {
  content: "\f10b";
}
.amicon-circle-o:before {
  content: "\f10c";
}
.amicon-quote-left:before {
  content: "\f10d";
}
.amicon-quote-right:before {
  content: "\f10e";
}
.amicon-spinner:before {
  content: "\f110";
}
.amicon-circle:before {
  content: "\f111";
}
.amicon-mail-reply:before {
  content: "\f112";
}
.amicon-reply:before {
  content: "\f112";
}
.amicon-github-alt:before {
  content: "\f113";
}
.amicon-folder-o:before {
  content: "\f114";
}
.amicon-folder-open-o:before {
  content: "\f115";
}
.amicon-smile-o:before {
  content: "\f118";
}
.amicon-frown-o:before {
  content: "\f119";
}
.amicon-meh-o:before {
  content: "\f11a";
}
.amicon-gamepad:before {
  content: "\f11b";
}
.amicon-keyboard-o:before {
  content: "\f11c";
}
.amicon-flag-o:before {
  content: "\f11d";
}
.amicon-flag-checkered:before {
  content: "\f11e";
}
.amicon-terminal:before {
  content: "\f120";
}
.amicon-code:before {
  content: "\f121";
}
.amicon-mail-reply-all:before {
  content: "\f122";
}
.amicon-reply-all:before {
  content: "\f122";
}
.amicon-star-half-empty:before {
  content: "\f123";
}
.amicon-star-half-full:before {
  content: "\f123";
}
.amicon-star-half-o:before {
  content: "\f123";
}
.amicon-location-arrow:before {
  content: "\f124";
}
.amicon-crop:before {
  content: "\f125";
}
.amicon-code-fork:before {
  content: "\f126";
}
.amicon-chain-broken:before {
  content: "\f127";
}
.amicon-unlink:before {
  content: "\f127";
}
.amicon-info:before {
  content: "\f129";
}
.amicon-exclamation:before {
  content: "\f12a";
}
.amicon-superscript:before {
  content: "\f12b";
}
.amicon-subscript:before {
  content: "\f12c";
}
.amicon-eraser:before {
  content: "\f12d";
}
.amicon-puzzle-piece:before {
  content: "\f12e";
}
.amicon-microphone:before {
  content: "\f130";
}
.amicon-microphone-slash:before {
  content: "\f131";
}
.amicon-shield:before {
  content: "\f132";
}
.amicon-calendar-o:before {
  content: "\f133";
}
.amicon-fire-extinguisher:before {
  content: "\f134";
}
.amicon-rocket:before {
  content: "\f135";
}
.amicon-maxcdn:before {
  content: "\f136";
}
.amicon-chevron-circle-left:before {
  content: "\f137";
}
.amicon-chevron-circle-right:before {
  content: "\f138";
}
.amicon-chevron-circle-up:before {
  content: "\f139";
}
.amicon-chevron-circle-down:before {
  content: "\f13a";
}
.amicon-html5:before {
  content: "\f13b";
}
.amicon-css3:before {
  content: "\f13c";
}
.amicon-anchor:before {
  content: "\f13d";
}
.amicon-unlock-alt:before {
  content: "\f13e";
}
.amicon-bullseye:before {
  content: "\f140";
}
.amicon-ellipsis-h:before {
  content: "\f141";
}
.amicon-ellipsis-v:before {
  content: "\f142";
}
.amicon-rss-square:before {
  content: "\f143";
}
.amicon-play-circle:before {
  content: "\f144";
}
.amicon-ticket:before {
  content: "\f145";
}
.amicon-minus-square:before {
  content: "\f146";
}
.amicon-minus-square-o:before {
  content: "\f147";
}
.amicon-level-up:before {
  content: "\f148";
}
.amicon-level-down:before {
  content: "\f149";
}
.amicon-check-square:before {
  content: "\f14a";
}
.amicon-pencil-square:before {
  content: "\f14b";
}
.amicon-external-link-square:before {
  content: "\f14c";
}
.amicon-share-square:before {
  content: "\f14d";
}
.amicon-compass:before {
  content: "\f14e";
}
.amicon-caret-square-o-down:before {
  content: "\f150";
}
.amicon-toggle-down:before {
  content: "\f150";
}
.amicon-caret-square-o-up:before {
  content: "\f151";
}
.amicon-toggle-up:before {
  content: "\f151";
}
.amicon-caret-square-o-right:before {
  content: "\f152";
}
.amicon-toggle-right:before {
  content: "\f152";
}
.amicon-eur:before {
  content: "\f153";
}
.amicon-euro:before {
  content: "\f153";
}
.amicon-gbp:before {
  content: "\f154";
}
.amicon-dollar:before {
  content: "\f155";
}
.amicon-usd:before {
  content: "\f155";
}
.amicon-inr:before {
  content: "\f156";
}
.amicon-rupee:before {
  content: "\f156";
}
.amicon-cny:before {
  content: "\f157";
}
.amicon-jpy:before {
  content: "\f157";
}
.amicon-rmb:before {
  content: "\f157";
}
.amicon-yen:before {
  content: "\f157";
}
.amicon-rouble:before {
  content: "\f158";
}
.amicon-rub:before {
  content: "\f158";
}
.amicon-ruble:before {
  content: "\f158";
}
.amicon-krw:before {
  content: "\f159";
}
.amicon-won:before {
  content: "\f159";
}
.amicon-bitcoin:before {
  content: "\f15a";
}
.amicon-btc:before {
  content: "\f15a";
}
.amicon-file:before {
  content: "\f15b";
}
.amicon-file-text:before {
  content: "\f15c";
}
.amicon-sort-alpha-asc:before {
  content: "\f15d";
}
.amicon-sort-alpha-desc:before {
  content: "\f15e";
}
.amicon-sort-amount-asc:before {
  content: "\f160";
}
.amicon-sort-amount-desc:before {
  content: "\f161";
}
.amicon-sort-numeric-asc:before {
  content: "\f162";
}
.amicon-sort-numeric-desc:before {
  content: "\f163";
}
.amicon-thumbs-up:before {
  content: "\f164";
}
.amicon-thumbs-down:before {
  content: "\f165";
}
.amicon-youtube-square:before {
  content: "\f166";
}
.amicon-youtube:before {
  content: "\f167";
}
.amicon-xing:before {
  content: "\f168";
}
.amicon-xing-square:before {
  content: "\f169";
}
.amicon-youtube-play:before {
  content: "\f16a";
}
.amicon-dropbox:before {
  content: "\f16b";
}
.amicon-stack-overflow:before {
  content: "\f16c";
}
.amicon-instagram:before {
  content: "\f16d";
}
.amicon-flickr:before {
  content: "\f16e";
}
.amicon-adn:before {
  content: "\f170";
}
.amicon-bitbucket:before {
  content: "\f171";
}
.amicon-bitbucket-square:before {
  content: "\f172";
}
.amicon-tumblr:before {
  content: "\f173";
}
.amicon-tumblr-square:before {
  content: "\f174";
}
.amicon-long-arrow-down:before {
  content: "\f175";
}
.amicon-long-arrow-up:before {
  content: "\f176";
}
.amicon-long-arrow-left:before {
  content: "\f177";
}
.amicon-long-arrow-right:before {
  content: "\f178";
}
.amicon-apple:before {
  content: "\f179";
}
.amicon-windows:before {
  content: "\f17a";
}
.amicon-android:before {
  content: "\f17b";
}
.amicon-linux:before {
  content: "\f17c";
}
.amicon-dribbble:before {
  content: "\f17d";
}
.amicon-skype:before {
  content: "\f17e";
}
.amicon-foursquare:before {
  content: "\f180";
}
.amicon-trello:before {
  content: "\f181";
}
.amicon-female:before {
  content: "\f182";
}
.amicon-male:before {
  content: "\f183";
}
.amicon-gittip:before {
  content: "\f184";
}
.amicon-gratipay:before {
  content: "\f184";
}
.amicon-sun-o:before {
  content: "\f185";
}
.amicon-moon-o:before {
  content: "\f186";
}
.amicon-archive:before {
  content: "\f187";
}
.amicon-bug:before {
  content: "\f188";
}
.amicon-vk:before {
  content: "\f189";
}
.amicon-weibo:before {
  content: "\f18a";
}
.amicon-renren:before {
  content: "\f18b";
}
.amicon-pagelines:before {
  content: "\f18c";
}
.amicon-stack-exchange:before {
  content: "\f18d";
}
.amicon-arrow-circle-o-right:before {
  content: "\f18e";
}
.amicon-arrow-circle-o-left:before {
  content: "\f190";
}
.amicon-caret-square-o-left:before {
  content: "\f191";
}
.amicon-toggle-left:before {
  content: "\f191";
}
.amicon-dot-circle-o:before {
  content: "\f192";
}
.amicon-wheelchair:before {
  content: "\f193";
}
.amicon-vimeo-square:before {
  content: "\f194";
}
.amicon-try:before {
  content: "\f195";
}
.amicon-turkish-lira:before {
  content: "\f195";
}
.amicon-plus-square-o:before {
  content: "\f196";
}
.amicon-space-shuttle:before {
  content: "\f197";
}
.amicon-slack:before {
  content: "\f198";
}
.amicon-envelope-square:before {
  content: "\f199";
}
.amicon-wordpress:before {
  content: "\f19a";
}
.amicon-openid:before {
  content: "\f19b";
}
.amicon-bank:before {
  content: "\f19c";
}
.amicon-institution:before {
  content: "\f19c";
}
.amicon-university:before {
  content: "\f19c";
}
.amicon-graduation-cap:before {
  content: "\f19d";
}
.amicon-mortar-board:before {
  content: "\f19d";
}
.amicon-yahoo:before {
  content: "\f19e";
}
.amicon-google:before {
  content: "\f1a0";
}
.amicon-reddit:before {
  content: "\f1a1";
}
.amicon-reddit-square:before {
  content: "\f1a2";
}
.amicon-stumbleupon-circle:before {
  content: "\f1a3";
}
.amicon-stumbleupon:before {
  content: "\f1a4";
}
.amicon-delicious:before {
  content: "\f1a5";
}
.amicon-digg:before {
  content: "\f1a6";
}
.amicon-pied-piper-pp:before {
  content: "\f1a7";
}
.amicon-pied-piper-alt:before {
  content: "\f1a8";
}
.amicon-drupal:before {
  content: "\f1a9";
}
.amicon-joomla:before {
  content: "\f1aa";
}
.amicon-language:before {
  content: "\f1ab";
}
.amicon-fax:before {
  content: "\f1ac";
}
.amicon-building:before {
  content: "\f1ad";
}
.amicon-child:before {
  content: "\f1ae";
}
.amicon-paw:before {
  content: "\f1b0";
}
.amicon-spoon:before {
  content: "\f1b1";
}
.amicon-cube:before {
  content: "\f1b2";
}
.amicon-cubes:before {
  content: "\f1b3";
}
.amicon-behance:before {
  content: "\f1b4";
}
.amicon-behance-square:before {
  content: "\f1b5";
}
.amicon-steam:before {
  content: "\f1b6";
}
.amicon-steam-square:before {
  content: "\f1b7";
}
.amicon-recycle:before {
  content: "\f1b8";
}
.amicon-automobile:before {
  content: "\f1b9";
}
.amicon-car:before {
  content: "\f1b9";
}
.amicon-cab:before {
  content: "\f1ba";
}
.amicon-taxi:before {
  content: "\f1ba";
}
.amicon-tree:before {
  content: "\f1bb";
}
.amicon-spotify:before {
  content: "\f1bc";
}
.amicon-deviantart:before {
  content: "\f1bd";
}
.amicon-soundcloud:before {
  content: "\f1be";
}
.amicon-database:before {
  content: "\f1c0";
}
.amicon-file-pdf-o:before {
  content: "\f1c1";
}
.amicon-file-word-o:before {
  content: "\f1c2";
}
.amicon-file-excel-o:before {
  content: "\f1c3";
}
.amicon-file-powerpoint-o:before {
  content: "\f1c4";
}
.amicon-file-image-o:before {
  content: "\f1c5";
}
.amicon-file-photo-o:before {
  content: "\f1c5";
}
.amicon-file-picture-o:before {
  content: "\f1c5";
}
.amicon-file-archive-o:before {
  content: "\f1c6";
}
.amicon-file-zip-o:before {
  content: "\f1c6";
}
.amicon-file-audio-o:before {
  content: "\f1c7";
}
.amicon-file-sound-o:before {
  content: "\f1c7";
}
.amicon-file-movie-o:before {
  content: "\f1c8";
}
.amicon-file-video-o:before {
  content: "\f1c8";
}
.amicon-file-code-o:before {
  content: "\f1c9";
}
.amicon-vine:before {
  content: "\f1ca";
}
.amicon-codepen:before {
  content: "\f1cb";
}
.amicon-jsfiddle:before {
  content: "\f1cc";
}
.amicon-life-bouy:before {
  content: "\f1cd";
}
.amicon-life-buoy:before {
  content: "\f1cd";
}
.amicon-life-ring:before {
  content: "\f1cd";
}
.amicon-life-saver:before {
  content: "\f1cd";
}
.amicon-support:before {
  content: "\f1cd";
}
.amicon-circle-o-notch:before {
  content: "\f1ce";
}
.amicon-ra:before {
  content: "\f1d0";
}
.amicon-rebel:before {
  content: "\f1d0";
}
.amicon-resistance:before {
  content: "\f1d0";
}
.amicon-empire:before {
  content: "\f1d1";
}
.amicon-ge:before {
  content: "\f1d1";
}
.amicon-git-square:before {
  content: "\f1d2";
}
.amicon-git:before {
  content: "\f1d3";
}
.amicon-hacker-news:before {
  content: "\f1d4";
}
.amicon-y-combinator-square:before {
  content: "\f1d4";
}
.amicon-yc-square:before {
  content: "\f1d4";
}
.amicon-tencent-weibo:before {
  content: "\f1d5";
}
.amicon-qq:before {
  content: "\f1d6";
}
.amicon-wechat:before {
  content: "\f1d7";
}
.amicon-weixin:before {
  content: "\f1d7";
}
.amicon-paper-plane:before {
  content: "\f1d8";
}
.amicon-send:before {
  content: "\f1d8";
}
.amicon-paper-plane-o:before {
  content: "\f1d9";
}
.amicon-send-o:before {
  content: "\f1d9";
}
.amicon-history:before {
  content: "\f1da";
}
.amicon-circle-thin:before {
  content: "\f1db";
}
.amicon-header:before {
  content: "\f1dc";
}
.amicon-paragraph:before {
  content: "\f1dd";
}
.amicon-sliders:before {
  content: "\f1de";
}
.amicon-share-alt:before {
  content: "\f1e0";
}
.amicon-share-alt-square:before {
  content: "\f1e1";
}
.amicon-bomb:before {
  content: "\f1e2";
}
.amicon-futbol-o:before {
  content: "\f1e3";
}
.amicon-soccer-ball-o:before {
  content: "\f1e3";
}
.amicon-tty:before {
  content: "\f1e4";
}
.amicon-binoculars:before {
  content: "\f1e5";
}
.amicon-plug:before {
  content: "\f1e6";
}
.amicon-slideshare:before {
  content: "\f1e7";
}
.amicon-twitch:before {
  content: "\f1e8";
}
.amicon-yelp:before {
  content: "\f1e9";
}
.amicon-newspaper-o:before {
  content: "\f1ea";
}
.amicon-wifi:before {
  content: "\f1eb";
}
.amicon-calculator:before {
  content: "\f1ec";
}
.amicon-paypal:before {
  content: "\f1ed";
}
.amicon-google-wallet:before {
  content: "\f1ee";
}
.amicon-cc-visa:before {
  content: "\f1f0";
}
.amicon-cc-mastercard:before {
  content: "\f1f1";
}
.amicon-cc-discover:before {
  content: "\f1f2";
}
.amicon-cc-amex:before {
  content: "\f1f3";
}
.amicon-cc-paypal:before {
  content: "\f1f4";
}
.amicon-cc-stripe:before {
  content: "\f1f5";
}
.amicon-bell-slash:before {
  content: "\f1f6";
}
.amicon-bell-slash-o:before {
  content: "\f1f7";
}
.amicon-trash:before {
  content: "\f1f8";
}
.amicon-copyright:before {
  content: "\f1f9";
}
.amicon-at:before {
  content: "\f1fa";
}
.amicon-eyedropper:before {
  content: "\f1fb";
}
.amicon-paint-brush:before {
  content: "\f1fc";
}
.amicon-birthday-cake:before {
  content: "\f1fd";
}
.amicon-area-chart:before {
  content: "\f1fe";
}
.amicon-pie-chart:before {
  content: "\f200";
}
.amicon-line-chart:before {
  content: "\f201";
}
.amicon-lastfm:before {
  content: "\f202";
}
.amicon-lastfm-square:before {
  content: "\f203";
}
.amicon-toggle-off:before {
  content: "\f204";
}
.amicon-toggle-on:before {
  content: "\f205";
}
.amicon-bicycle:before {
  content: "\f206";
}
.amicon-bus:before {
  content: "\f207";
}
.amicon-ioxhost:before {
  content: "\f208";
}
.amicon-angellist:before {
  content: "\f209";
}
.amicon-cc:before {
  content: "\f20a";
}
.amicon-ils:before {
  content: "\f20b";
}
.amicon-shekel:before {
  content: "\f20b";
}
.amicon-sheqel:before {
  content: "\f20b";
}
.amicon-meanpath:before {
  content: "\f20c";
}
.amicon-buysellads:before {
  content: "\f20d";
}
.amicon-connectdevelop:before {
  content: "\f20e";
}
.amicon-dashcube:before {
  content: "\f210";
}
.amicon-forumbee:before {
  content: "\f211";
}
.amicon-leanpub:before {
  content: "\f212";
}
.amicon-sellsy:before {
  content: "\f213";
}
.amicon-shirtsinbulk:before {
  content: "\f214";
}
.amicon-simplybuilt:before {
  content: "\f215";
}
.amicon-skyatlas:before {
  content: "\f216";
}
.amicon-cart-plus:before {
  content: "\f217";
}
.amicon-cart-arrow-down:before {
  content: "\f218";
}
.amicon-diamond:before {
  content: "\f219";
}
.amicon-ship:before {
  content: "\f21a";
}
.amicon-user-secret:before {
  content: "\f21b";
}
.amicon-motorcycle:before {
  content: "\f21c";
}
.amicon-street-view:before {
  content: "\f21d";
}
.amicon-heartbeat:before {
  content: "\f21e";
}
.amicon-venus:before {
  content: "\f221";
}
.amicon-mars:before {
  content: "\f222";
}
.amicon-mercury:before {
  content: "\f223";
}
.amicon-intersex:before {
  content: "\f224";
}
.amicon-transgender:before {
  content: "\f224";
}
.amicon-transgender-alt:before {
  content: "\f225";
}
.amicon-venus-double:before {
  content: "\f226";
}
.amicon-mars-double:before {
  content: "\f227";
}
.amicon-venus-mars:before {
  content: "\f228";
}
.amicon-mars-stroke:before {
  content: "\f229";
}
.amicon-mars-stroke-v:before {
  content: "\f22a";
}
.amicon-mars-stroke-h:before {
  content: "\f22b";
}
.amicon-neuter:before {
  content: "\f22c";
}
.amicon-genderless:before {
  content: "\f22d";
}
.amicon-facebook-official:before {
  content: "\f230";
}
.amicon-pinterest-p:before {
  content: "\f231";
}
.amicon-whatsapp:before {
  content: "\f232";
}
.amicon-server:before {
  content: "\f233";
}
.amicon-user-plus:before {
  content: "\f234";
}
.amicon-user-times:before {
  content: "\f235";
}
.amicon-bed:before {
  content: "\f236";
}
.amicon-hotel:before {
  content: "\f236";
}
.amicon-viacoin:before {
  content: "\f237";
}
.amicon-train:before {
  content: "\f238";
}
.amicon-subway:before {
  content: "\f239";
}
.amicon-medium:before {
  content: "\f23a";
}
.amicon-y-combinator:before {
  content: "\f23b";
}
.amicon-yc:before {
  content: "\f23b";
}
.amicon-optin-monster:before {
  content: "\f23c";
}
.amicon-opencart:before {
  content: "\f23d";
}
.amicon-expeditedssl:before {
  content: "\f23e";
}
.amicon-battery:before {
  content: "\f240";
}
.amicon-battery-4:before {
  content: "\f240";
}
.amicon-battery-full:before {
  content: "\f240";
}
.amicon-battery-3:before {
  content: "\f241";
}
.amicon-battery-three-quarters:before {
  content: "\f241";
}
.amicon-battery-2:before {
  content: "\f242";
}
.amicon-battery-half:before {
  content: "\f242";
}
.amicon-battery-1:before {
  content: "\f243";
}
.amicon-battery-quarter:before {
  content: "\f243";
}
.amicon-battery-0:before {
  content: "\f244";
}
.amicon-battery-empty:before {
  content: "\f244";
}
.amicon-mouse-pointer:before {
  content: "\f245";
}
.amicon-i-cursor:before {
  content: "\f246";
}
.amicon-object-group:before {
  content: "\f247";
}
.amicon-object-ungroup:before {
  content: "\f248";
}
.amicon-sticky-note:before {
  content: "\f249";
}
.amicon-sticky-note-o:before {
  content: "\f24a";
}
.amicon-cc-jcb:before {
  content: "\f24b";
}
.amicon-cc-diners-club:before {
  content: "\f24c";
}
.amicon-clone:before {
  content: "\f24d";
}
.amicon-balance-scale:before {
  content: "\f24e";
}
.amicon-hourglass-o:before {
  content: "\f250";
}
.amicon-hourglass-1:before {
  content: "\f251";
}
.amicon-hourglass-start:before {
  content: "\f251";
}
.amicon-hourglass-2:before {
  content: "\f252";
}
.amicon-hourglass-half:before {
  content: "\f252";
}
.amicon-hourglass-3:before {
  content: "\f253";
}
.amicon-hourglass-end:before {
  content: "\f253";
}
.amicon-hourglass:before {
  content: "\f254";
}
.amicon-hand-grab-o:before {
  content: "\f255";
}
.amicon-hand-rock-o:before {
  content: "\f255";
}
.amicon-hand-paper-o:before {
  content: "\f256";
}
.amicon-hand-stop-o:before {
  content: "\f256";
}
.amicon-hand-scissors-o:before {
  content: "\f257";
}
.amicon-hand-lizard-o:before {
  content: "\f258";
}
.amicon-hand-spock-o:before {
  content: "\f259";
}
.amicon-hand-pointer-o:before {
  content: "\f25a";
}
.amicon-hand-peace-o:before {
  content: "\f25b";
}
.amicon-trademark:before {
  content: "\f25c";
}
.amicon-registered:before {
  content: "\f25d";
}
.amicon-creative-commons:before {
  content: "\f25e";
}
.amicon-gg:before {
  content: "\f260";
}
.amicon-gg-circle:before {
  content: "\f261";
}
.amicon-tripadvisor:before {
  content: "\f262";
}
.amicon-odnoklassniki:before {
  content: "\f263";
}
.amicon-odnoklassniki-square:before {
  content: "\f264";
}
.amicon-get-pocket:before {
  content: "\f265";
}
.amicon-wikipedia-w:before {
  content: "\f266";
}
.amicon-safari:before {
  content: "\f267";
}
.amicon-chrome:before {
  content: "\f268";
}
.amicon-firefox:before {
  content: "\f269";
}
.amicon-opera:before {
  content: "\f26a";
}
.amicon-internet-explorer:before {
  content: "\f26b";
}
.amicon-television:before {
  content: "\f26c";
}
.amicon-tv:before {
  content: "\f26c";
}
.amicon-contao:before {
  content: "\f26d";
}
.amicon-500px:before {
  content: "\f26e";
}
.amicon-amazon:before {
  content: "\f270";
}
.amicon-calendar-plus-o:before {
  content: "\f271";
}
.amicon-calendar-minus-o:before {
  content: "\f272";
}
.amicon-calendar-times-o:before {
  content: "\f273";
}
.amicon-calendar-check-o:before {
  content: "\f274";
}
.amicon-industry:before {
  content: "\f275";
}
.amicon-map-pin:before {
  content: "\f276";
}
.amicon-map-signs:before {
  content: "\f277";
}
.amicon-map-o:before {
  content: "\f278";
}
.amicon-map:before {
  content: "\f279";
}
.amicon-commenting:before {
  content: "\f27a";
}
.amicon-commenting-o:before {
  content: "\f27b";
}
.amicon-houzz:before {
  content: "\f27c";
}
.amicon-vimeo:before {
  content: "\f27d";
}
.amicon-black-tie:before {
  content: "\f27e";
}
.amicon-fonticons:before {
  content: "\f280";
}
.amicon-reddit-alien:before {
  content: "\f281";
}
.amicon-edge:before {
  content: "\f282";
}
.amicon-credit-card-alt:before {
  content: "\f283";
}
.amicon-codiepie:before {
  content: "\f284";
}
.amicon-modx:before {
  content: "\f285";
}
.amicon-fort-awesome:before {
  content: "\f286";
}
.amicon-usb:before {
  content: "\f287";
}
.amicon-product-hunt:before {
  content: "\f288";
}
.amicon-mixcloud:before {
  content: "\f289";
}
.amicon-scribd:before {
  content: "\f28a";
}
.amicon-pause-circle:before {
  content: "\f28b";
}
.amicon-pause-circle-o:before {
  content: "\f28c";
}
.amicon-stop-circle:before {
  content: "\f28d";
}
.amicon-stop-circle-o:before {
  content: "\f28e";
}
.amicon-shopping-bag:before {
  content: "\f290";
}
.amicon-shopping-basket:before {
  content: "\f291";
}
.amicon-hashtag:before {
  content: "\f292";
}
.amicon-bluetooth:before {
  content: "\f293";
}
.amicon-bluetooth-b:before {
  content: "\f294";
}
.amicon-percent:before {
  content: "\f295";
}
.amicon-gitlab:before {
  content: "\f296";
}
.amicon-wpbeginner:before {
  content: "\f297";
}
.amicon-wpforms:before {
  content: "\f298";
}
.amicon-envira:before {
  content: "\f299";
}
.amicon-universal-access:before {
  content: "\f29a";
}
.amicon-wheelchair-alt:before {
  content: "\f29b";
}
.amicon-question-circle-o:before {
  content: "\f29c";
}
.amicon-blind:before {
  content: "\f29d";
}
.amicon-audio-description:before {
  content: "\f29e";
}
.amicon-volume-control-phone:before {
  content: "\f2a0";
}
.amicon-braille:before {
  content: "\f2a1";
}
.amicon-assistive-listening-systems:before {
  content: "\f2a2";
}
.amicon-american-sign-language-interpreting:before {
  content: "\f2a3";
}
.amicon-asl-interpreting:before {
  content: "\f2a3";
}
.amicon-deaf:before {
  content: "\f2a4";
}
.amicon-deafness:before {
  content: "\f2a4";
}
.amicon-hard-of-hearing:before {
  content: "\f2a4";
}
.amicon-glide:before {
  content: "\f2a5";
}
.amicon-glide-g:before {
  content: "\f2a6";
}
.amicon-sign-language:before {
  content: "\f2a7";
}
.amicon-signing:before {
  content: "\f2a7";
}
.amicon-low-vision:before {
  content: "\f2a8";
}
.amicon-viadeo:before {
  content: "\f2a9";
}
.amicon-viadeo-square:before {
  content: "\f2aa";
}
.amicon-snapchat:before {
  content: "\f2ab";
}
.amicon-snapchat-ghost:before {
  content: "\f2ac";
}
.amicon-snapchat-square:before {
  content: "\f2ad";
}
.amicon-pied-piper:before {
  content: "\f2ae";
}
.amicon-first-order:before {
  content: "\f2b0";
}
.amicon-yoast:before {
  content: "\f2b1";
}
.amicon-themeisle:before {
  content: "\f2b2";
}
.amicon-google-plus-circle:before {
  content: "\f2b3";
}
.amicon-google-plus-official:before {
  content: "\f2b3";
}
.amicon-fa:before {
  content: "\f2b4";
}
.amicon-font-awesome:before {
  content: "\f2b4";
}
.amicon-handshake-o:before {
  content: "\f2b5";
}
.amicon-envelope-open:before {
  content: "\f2b6";
}
.amicon-envelope-open-o:before {
  content: "\f2b7";
}
.amicon-linode:before {
  content: "\f2b8";
}
.amicon-address-book:before {
  content: "\f2b9";
}
.amicon-address-book-o:before {
  content: "\f2ba";
}
.amicon-address-card:before {
  content: "\f2bb";
}
.amicon-vcard:before {
  content: "\f2bb";
}
.amicon-address-card-o:before {
  content: "\f2bc";
}
.amicon-vcard-o:before {
  content: "\f2bc";
}
.amicon-user-circle:before {
  content: "\f2bd";
}
.amicon-user-circle-o:before {
  content: "\f2be";
}
.amicon-user-o:before {
  content: "\f2c0";
}
.amicon-id-badge:before {
  content: "\f2c1";
}
.amicon-drivers-license:before {
  content: "\f2c2";
}
.amicon-id-card:before {
  content: "\f2c2";
}
.amicon-drivers-license-o:before {
  content: "\f2c3";
}
.amicon-id-card-o:before {
  content: "\f2c3";
}
.amicon-quora:before {
  content: "\f2c4";
}
.amicon-free-code-camp:before {
  content: "\f2c5";
}
.amicon-telegram:before {
  content: "\f2c6";
}
.amicon-thermometer:before {
  content: "\f2c7";
}
.amicon-thermometer-4:before {
  content: "\f2c7";
}
.amicon-thermometer-full:before {
  content: "\f2c7";
}
.amicon-thermometer-3:before {
  content: "\f2c8";
}
.amicon-thermometer-three-quarters:before {
  content: "\f2c8";
}
.amicon-thermometer-2:before {
  content: "\f2c9";
}
.amicon-thermometer-half:before {
  content: "\f2c9";
}
.amicon-thermometer-1:before {
  content: "\f2ca";
}
.amicon-thermometer-quarter:before {
  content: "\f2ca";
}
.amicon-thermometer-0:before {
  content: "\f2cb";
}
.amicon-thermometer-empty:before {
  content: "\f2cb";
}
.amicon-shower:before {
  content: "\f2cc";
}
.amicon-bath:before {
  content: "\f2cd";
}
.amicon-bathtub:before {
  content: "\f2cd";
}
.amicon-s15:before {
  content: "\f2cd";
}
.amicon-podcast:before {
  content: "\f2ce";
}
.amicon-window-maximize:before {
  content: "\f2d0";
}
.amicon-window-minimize:before {
  content: "\f2d1";
}
.amicon-window-restore:before {
  content: "\f2d2";
}
.amicon-times-rectangle:before {
  content: "\f2d3";
}
.amicon-window-close:before {
  content: "\f2d3";
}
.amicon-times-rectangle-o:before {
  content: "\f2d4";
}
.amicon-window-close-o:before {
  content: "\f2d4";
}
.amicon-bandcamp:before {
  content: "\f2d5";
}
.amicon-grav:before {
  content: "\f2d6";
}
.amicon-etsy:before {
  content: "\f2d7";
}
.amicon-imdb:before {
  content: "\f2d8";
}
.amicon-ravelry:before {
  content: "\f2d9";
}
.amicon-eercast:before {
  content: "\f2da";
}
.amicon-microchip:before {
  content: "\f2db";
}
.amicon-snowflake-o:before {
  content: "\f2dc";
}
.amicon-superpowers:before {
  content: "\f2dd";
}
.amicon-wpexplorer:before {
  content: "\f2de";
}
.amicon-meetup:before {
  content: "\f2e0";
}
